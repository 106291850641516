import { defineStore } from 'pinia'

export const useStoreQuadrosClaro = defineStore('storeQuadrosClaro', {
  state: () => ({
    personalizacao: {
      telaLogin: {
        layout: 'tres',
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corQuadro: '#FFFFFF',
        corTitulo: '#000000',
        corTextoBotao: '#FFFFFF',
        corFundoBotao: '',
        corLabels: '#000000',
        corFundoCampos: '#EBEAF5',
        corTextoCampos: '#000000',
        corBotoesExtras: '#000000',
        textoTitulo: '',
        textoBotaoEntrar: 'Entrar Agora',
        textoBotaoExtraUm: 'Suporte Whatsapp',
        textoBotaoExtraDois: 'Perfil Instagram',
        imagemLogo: '',
        imagemFundo: '',
        linkBotaoExtraUm: 'https://whatsapp.com',
        linkBotaoExtraDois: 'https://instagram.com'
      },
      telaAlterarSenha: {
        layout: 'tres',
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corQuadro: '#FFFFFF',
        corTitulo: '#000000',
        corTextoBotao: '#FFFFFF',
        corFundoBotao: '',
        corLabels: '#000000',
        corFundoCampos: '#EBEAF5',
        corTextoCampos: '#000000',
        corBotoesExtras: '#000000',
        textoTitulo: '',
        textoBotaoAlterar: 'Recuperar Senha',
        textoBotaoExtra: 'Suporte Whatsapp',
        imagemLogo: '',
        imagemFundo: '',
        linkBotaoExtra: 'https://whatsapp.com'
      },
      telaLoading: {
        fonte: 'Inter',
        corFundo: '#FFFFFF',
        corTitulo: '#000000',
        corLoading: '',
        textoTitulo: 'Carregando seu acesso e conteúdos...',
        imagemLogo: '',
        imagemFundo: ''
      },
      telaInicio: {
        layout: 'dois',
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corQuadro: '#FFFFFF',
        corTextos: '#000000',
        corTextosDois: '#A5A5A5',
        corFundoBotao: '',
        corTextoBotao: '#FFFFFF',
        corTextoCapa: '#FFFFFF',
        corProgressoFundo: '#ECECEC',
        corProgressoAtual: '',
        textoCapaTitulo: '',
        textoCapaDescricao: 'Essa é a sua área de estudos',
        textoCapaBotao: 'Comece por Aqui',
        textoBotao: 'Ver módulos',
        textoAulas: 'Seu progresso',
        imagemBannerDesktop: '',
        imagemBannerMobile: '',
        imagemCapaDesktop: '',
        imagemCapaMobile: '',
        linkBanner: '',
        linkCapa: ''
      },
      telaCurso: {
        layout: 'dois',
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corQuadro: '#FFFFFF',
        corTitulos: '#000000',
        corTags: '#999999',
        corFundoBotao: '',
        corTextoBotao: '#FFFFFF',
        corPosicaoTexto: '#000000',
        corPosicaoFundo: '#EAEAEA',
        corCaminho: '',
        textoBotao: 'Ver aulas',
        imagemFundo: ''
      },
      telaModulo: {
        layout: 'tres',
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corQuadro: '#FFFFFF',
        corTitulos: '#000000',
        corTags: '#999999',
        corFundoBotao: '',
        corTextoBotao: '#FFFFFF',
        corPosicaoTexto: '#000000',
        corPosicaoFundo: '#EAEAEA',
        corCaminho: '',
        textoBotao: 'Assistir Aula',
        imagemFundo: ''
      },
      telaAula: {
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corTitulo: '#000000',
        corDescricao: '#595959',
        corDuracao: '#888989',
        corLinks: '',
        corArquivos: '',
        corPosicaoTexto: '#000000',
        corPosicaoFundo: '#FFFFFF',
        corFundoCampoComentario: '#FFFFFF',
        corTextoCampoComentario: '#000000',
        corTextosComentarios: '#000000',
        corTextoBotaoComentar: '#FFFFFF',
        corFundoBotaoComentar: '',
        corTextoBotaoResponder: '#000000',
        corFundoBotaoResponder: '#ffffff',
        corTextoDataComentario: '#8D8D8D',
        corFundoAula: '#FFFFFF',
        corTextoAula: '#000000',
        corSvgBotaoAula: '#ffffff',
        corFundoBotaoAula: '',
        corTextoPosicaoAula: '#000000',
        corFundoPosicaoAula: '#EAEAEA',
        corTextoDuracaoAula: '#FFFFFF',
        corFundoDuracaoAula: '#000000'
      },
      telaPerfil: {
        layout: '',
        fonte: 'Inter',
        corFundo: '#EBEAF5',
        corNome: '#000000',
        corEmail: '#848585',
        corBotoesFundo: '#FFFFFF',
        corBotoesTexto: '#000000',
        imagemCapa: ''
      },
      menu: {
        layout: '',
        fonte: 'Inter',
        corFundo: '#FFFFFF',
        corTitulo: '#000000',
        corBotoes: '#000000',
        corIcones: '',
        textoTitulo: '',
        textoBotaoExtraUm: 'Suporte Whatsapp',
        textoBotaoExtraDois: 'Perfil Instagram',
        textoBotaoExtraTres: 'Canal Youtube',
        imagemLogo: '',
        imagemBotaoExtraUm: '',
        imagemBotaoExtraDois: '',
        imagemBotaoExtraTres: '',
        linkBotaoExtraUm: 'https://whatsapp.com',
        linkBotaoExtraDois: 'https://instagram.com',
        linkBotaoExtraTres: 'https://youtube.com'
      }
    }
  })
})
