<template>
  <ModalPersonalizacao nome="loginEditarTextos">
    <template #um>
      <div class="campos">
        <label>Título</label>
        <textarea rows="1" spellcheck="false" placeholder="Texto aqui..." v-model="storeAtualizada.textoTitulo" v-redimensionar-textarea></textarea>

        <label>Texto Botão Entrar</label>
        <textarea rows="1" placeholder="Texto aqui..." spellcheck="false" autocomplete="false" v-model="storeAtualizada.textoBotaoEntrar" v-redimensionar-textarea></textarea>

        <label>Texto Botão 1</label>
        <textarea rows="1" placeholder="Texto aqui..." spellcheck="false" autocomplete="false" v-model="storeAtualizada.textoBotaoExtraUm" v-redimensionar-textarea></textarea>

        <label>Texto Botão 2</label>
        <textarea rows="1" placeholder="Texto aqui..." spellcheck="false" autocomplete="false" v-model="storeAtualizada.textoBotaoExtraDois" v-redimensionar-textarea></textarea>
      </div>
      <SalvarModal nome="Salvar Textos" @click="salvarDados()" />
    </template>
  </ModalPersonalizacao>
</template>

<script setup>
import { inject, computed } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ModalPersonalizacao from '@components/global/modal/ModalPersonalizacao.vue'

const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()
const emitter = inject('emitter')

async function salvarDados() {
  const payload = {
    idArea: storeAreas.area._id,
    personalizacao: {
      telaLogin: storeAtualizada.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarArea')
}

const storeAtualizada = computed(() => storePersonalizacao.personalizacao?.telaLogin)
</script>

<style scoped>
.campos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
}
</style>
