<template>
  <div id="modulos">
    <Loading :class="{ mostrar: state.verLoading }" />

    <ModalEditarModulo />
    <ModalCriarModulo />
    <ModalExcluirModulo />
    <ModalDuplicarModulo />
    <ModalPosicaoModulos />

    <SectionTopo />
    <SectionModulos />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreCursos, useStoreAreas, useStoreModulos } from '@stores'

import Loading from '@components/global/loading/LoadingArea.vue'
import ModalCriarModulo from '@components/areaDeMembros/modulos/modals/CriarModulo.vue'
import ModalExcluirModulo from '@components/areaDeMembros/modulos/modals/ExcluirModulo.vue'
import ModalEditarModulo from '@components/areaDeMembros/modulos/modals/EditarModulo.vue'
import ModalDuplicarModulo from '@components/areaDeMembros/modulos/modals/DuplicarModulo.vue'
import ModalPosicaoModulos from '@components/areaDeMembros/modulos/modals/EditarPosicaoModulos.vue'

import SectionModulos from '@components/areaDeMembros/modulos/partials/SectionModulos.vue'
import SectionTopo from '@components/areaDeMembros/modulos/partials/SectionTopo.vue'

const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const state = reactive({
  verLoading: true
})

async function carregarModulos() {
  state.verLoading = true

  const idArea = storeAreas.area?._id

  if (idArea) {
    await storeCursos.receberTodos(idArea)
  }

  const idCurso = storeCursos.curso?._id || storeCursos.cursos?.[0]?._id

  if (idCurso) {
    await storeCursos.receberPorId(idArea, idCurso)
    await storeModulos.receberTodos(idArea, idCurso)
  }

  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

watch(
  () => [storeAreas.area?._id],
  ([idArea]) => {
    if (idArea) {
      carregarModulos()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarModulos', carregarModulos)
})
</script>

<style scoped>
#modulos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
