import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiModulos from '../api/produtor/api-modulos'

export const useStoreModulos = defineStore('storeModulos', {
  state: () => {
    return {
      modulos: null,
      modulo: null
    }
  },
  actions: {
    async receberTodos(idArea, idCurso) {
      try {
        const resp = await apiModulos.receberTodos(idArea, idCurso)
        this.modulos = resp
        return resp
      } catch (error) {
        this.modulos = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(idArea, idCurso, hotlink) {
      try {
        const resp = await apiModulos.receberPorHotlink(idArea, idCurso, hotlink)
        this.modulo = resp
        return true
      } catch (error) {
        this.modulo = null
        return false
      }
    },

    async receberPorId(idArea, idCurso, idModulo) {
      try {
        const resp = await apiModulos.receberPorId(idArea, idCurso, idModulo)
        this.modulo = resp
        return true
      } catch (error) {
        this.modulo = null
        return false
      }
    },

    async adicionar(idArea, idCurso, payload) {
      try {
        const resp = await apiModulos.adicionar(idArea, idCurso, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async duplicar(idArea, idCurso, idModulo) {
      try {
        const resp = await apiModulos.duplicar(idArea, idCurso, idModulo)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(idArea, idCurso, idModulo, payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiModulos.alterarDados(idArea, idCurso, idModulo, payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idArea, idCurso, idModulo) {
      try {
        const resp = await apiModulos.deletar(idArea, idCurso, idModulo)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async ordenarPosicoes(idArea, idCurso, payload) {
      try {
        const resp = await apiModulos.ordenarPosicoes(idArea, idCurso, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
