import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiNotificacoes from '@/api/produtor/api-notificacoes'

export const useStoreNotificacoes = defineStore('storeNotificacoes', {
  state: () => {
    return {
      notificacoes: null,
      notificacao: null
    }
  },
  actions: {
    async receberTodos(idArea = null) {
      try {
        const resp = await apiNotificacoes.receberTodos(idArea)
        this.notificacoes = resp
        return resp
      } catch (error) {
        this.notificacoes = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorId(idNotificacao) {
      try {
        const resp = await apiNotificacoes.receberPorId(idNotificacao)
        this.notificacao = resp
        return true
      } catch (error) {
        this.notificacao = null
        return false
      }
    },

    async alterarVisto(idNotificacao, payload) {
      try {
        const resp = await apiNotificacoes.alterarVisto(idNotificacao, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
