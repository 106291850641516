<template>
  <section class="alunos">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar curso..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
        </select>
      </div>
    </div>
    <div v-for="aluno in alunosFiltrados" :key="aluno._id" class="aluno">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${aluno.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="nome">
        <h3>{{ aluno.nome }}</h3>
        <p>{{ aluno.email }}</p>
      </div>

      <div class="dados">
        <div class="dado">
          <Svgs nome="relogio" />
          <p>Último acesso {{ cadastroData(aluno.dataUltimoAcesso) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="curso" />
          <p>
            <span>{{ aluno.numeroCursosComprados }}</span>
          </p>
          <p>{{ textoCursos(aluno.numeroCursosComprados) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="dinheiro" />
          <p>
            <span>{{ aluno.pontos }}</span>
          </p>
          <p>{{ textoPontos(aluno.pontos) }}</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="whatsapp">
          <Svgs nome="whatsapp" />
        </button>
        <button class="senha" @click="abrirModalEdicao('resetarSenha', 'cadeado', 'Resetar senha', aluno._id)">
          <Svgs nome="cadeado" />
        </button>
        <button class="entrar" @click="abrirModalEdicao('editarAluno', 'usuario', 'Visualizar Aluno', aluno._id)">
          <p>Ver aluno</p>
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStoreModal, useStoreAlunos } from '@stores'
import Svgs from '@svgs'

const storeAlunos = useStoreAlunos()
const storeModal = useStoreModal()

const searchQuery = ref('')
const selectedFilter = ref('todos')

const alunosFiltrados = computed(() => {
  let alunos = storeAlunos.alunos || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    alunos = alunos.filter((aluno) => aluno.nome.toLowerCase().includes(query) || aluno.email.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      alunos = alunos.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      alunos = alunos.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      alunos = alunos.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      alunos = alunos.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
  }

  return alunos
})

function abrirModalEdicao(nome, icone, titulo, id) {
  storeModal.abrirModalEdicao(nome, icone, titulo, id)
}

const textoCursos = (quantidade) => {
  return `${quantidade === 1 ? 'curso' : 'cursos'}`
}

const textoPontos = (quantidade) => {
  return `${quantidade === 1 ? 'ponto' : 'pontos'}`
}

function cadastroData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()

    return `em ${day}/${month}/${year}`
  }
}
</script>

<style scoped>
section.alunos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 100dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.busca .input {
  width: calc(80% - 8px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 8px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.aluno {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-1);
  border-top: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.aluno:last-child {
  border-bottom: 1px solid var(--cor-cinza-3);
}

.aluno .capa {
  width: 100%;
  max-width: 50px;
}

.aluno .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}

.aluno .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.aluno .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.aluno .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.aluno .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.aluno .opcoes {
  display: flex;
  align-items: center;
}

.aluno .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s;
}

.aluno .opcoes button.senha {
  margin: 0 10px;
}

.aluno .opcoes button.entrar {
  justify-content: space-between;
  width: 140px;
  padding: 0 15px;
}

.aluno .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.aluno .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.aluno .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.aluno .dados {
  display: flex;
  align-items: center;
}

.aluno .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.aluno .dados .dado:nth-child(1) {
  border-left: none;
}

.aluno .dados .dado svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
}

.aluno .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.aluno .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.alunos {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(65% - 8px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 8px);
  }

  .aluno {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .aluno .capa {
    width: 100%;
    max-width: 50px;
  }

  .aluno .nome {
    margin: 0;
    width: calc(100% - 50px);
  }

  .aluno .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .aluno .opcoes button {
    margin: 0;
  }

  .aluno .dados {
    padding: 20px 0;
    width: 100%;
  }

  .aluno .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .aluno .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .aluno .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
