<template>
  <button
    :class="{ ativo: modalAberto === 'menuEditarCores' }"
    @click="
      abrirModal(
        'menuEditarCores', // nome do modal
        'degrade-azul', // cor do icone
        'menu', // nome do icone
        'Menu da Área', // titulo
        'Editar Cores' // subtitulo
      )
    ">
    <p>Editar Cores</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'menuEditarTextos' }"
    @click="
      abrirModal(
        'menuEditarTextos', // nome do modal
        'degrade-azul', // cor do icone
        'menu', // nome do icone
        'Menu da Área', // titulo
        'Editar Textos' // subtitulo
      )
    ">
    <p>Editar Textos</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'menuEditarLinks' }"
    @click="
      abrirModal(
        'menuEditarLinks', // nome do modal
        'degrade-azul', // cor do icone
        'menu', // nome do icone
        'Menu da Área', // titulo
        'Editar Links' // subtitulo
      )
    ">
    <p>Editar Links</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'menuEditarFontes' }"
    @click="
      abrirModal(
        'menuEditarFontes', // nome do modal
        'degrade-azul', // cor do icone
        'menu', // nome do icone
        'Menu da Área', // titulo
        'Editar Fontes' // subtitulo
      )
    ">
    <p>Editar Fontes</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'menuEditarImagens' }"
    @click="
      abrirModal(
        'menuEditarImagens', // nome do modal
        'degrade-azul', // cor do icone
        'menu', // nome do icone
        'Menu da Área', // titulo
        'Editar Imagens' // subtitulo
      )
    ">
    <p>Editar Imagens</p>
  </button>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useStoreModal } from '@stores'

const storeModal = useStoreModal()
const modalAberto = ref('')
const emitter = inject('emitter')

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  if (modalAberto.value && modalAberto.value !== nome) {
    storeModal.fecharModal(modalAberto.value)
  }
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
  modalAberto.value = nome
}

function fecharModal() {
  modalAberto.value = ''
}

onMounted(() => {
  emitter.on('limparCampos', fecharModal)
})
</script>
