import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiPerfil from '../api/produtor/api-perfil'

export const useStorePerfil = defineStore('storePerfil', {
  state: () => {
    return {
      usuario: null,
      limiteAlunos: '',
      limiteAreas: ''
    }
  },
  actions: {
    async receberPerfil() {
      try {
        const resp = await apiPerfil.receberPorId()
        this.usuario = resp
        return true
      } catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(payload) {
      try {
        const resp = await apiPerfil.alterarDados(payload)
        return useStoreAlerta().exibirSucessoRequest(resp)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarSenha(payload) {
      try {
        const resp = await apiPerfil.alterarSenha(payload)
        return useStoreAlerta().exibirSucessoRequest(resp)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
