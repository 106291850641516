import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberPorHotlink(hotlink) {
    const method = 'GET'
    const url = `areas/hotlink/${idProdutor}/${hotlink}`

    return api(method, url, null, token)
  },

  receberPorId(idArea) {
    const method = 'GET'
    const url = `areas/${idProdutor}/${idArea}`

    return api(method, url, null, token)
  },

  receberTodos() {
    const method = 'GET'
    const url = `areas/${idProdutor}`

    return api(method, url, null, token)
  },

  adicionar(payload) {
    const method = 'POST'
    const url = `areas/${idProdutor}`
    const dados = payload

    return api(method, url, dados, token)
  },

  duplicar(idArea) {
    const method = 'POST'
    const url = `duplicar/area/${idProdutor}/${idArea}`

    return api(method, url, null, token)
  },

  alterarDados(payload) {
    const method = 'PUT'
    const url = `areas/${idProdutor}/${payload.idArea}`
    const dados = payload

    return api(method, url, dados, token)
  },

  alterarDominio(payload) {
    if (!idProdutor) return

    const method = 'PATCH'
    const url = `areas/dominio-personalizado/alterar/${idProdutor}/${payload.idArea}`

    return api(method, url, payload, token)
  },

  removerDominio(payload) {
    if (!idProdutor) return

    const method = 'PATCH'
    const url = `areas/dominio-personalizado/remover/${idProdutor}/${payload.idArea}`

    return api(method, url, payload, token)
  },

  deletar(idArea) {
    const method = 'DELETE'
    const url = `areas/${idProdutor}/${idArea}`
    const dados = {}

    return api(method, url, dados, token)
  }
}
