<template>
  <Modal nome="duplicarArea">
    <div class="conteudo">
      <div class="item">
        <div class="info">
          <div class="nome">
            <h3>{{ state.area?.nome }}</h3>
            <p>{{ state.area?.descricao }}</p>
          </div>
          <div class="status on">
            <span></span>
            <h3>Online</h3>
          </div>
        </div>
        <div class="links">
          <a class="dominio" target="_blank" :href="`https://${state.area?.dominioPersonalizado}`" :class="{ sem: !state.area?.dominioPersonalizado }">
            {{ state.area?.dominioPersonalizado || 'Sem domínio' }}
          </a>
          <a class="hotlink" target="_blank" :href="`https://mber.live/${state.area?.hotlink}`">mber.live/{{ state.area?.hotlink }}</a>
        </div>
      </div>

      <BotaoSalvar nome="Duplicar área" @click="duplicarArea" />
    </div>
  </Modal>
</template>
<script setup>
import { inject, reactive, computed, watch, onMounted } from 'vue'
import { useStoreAreas, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('duplicarArea'))

const state = reactive({
  area: []
})

async function duplicarArea() {
  const idArea = state.area?._id

  const duplicacaoOk = await storeAreas.duplicar(idArea)
  if (duplicacaoOk !== true) return

  emitter.emit('atualizarAreas')
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Área duplicada com sucesso!' })
  storeModal.fecharModal('duplicarArea')
}

async function pegarIdArea() {
  if (dadosModal.value.id) {
    await storeAreas.receberPorId(dadosModal.value.id)
    state.area = storeAreas.area
  }
}

function limparCampos() {
  state.area = []
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdArea()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.inputUrl {
  margin: 0 0 20px 0;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .status {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 10px 15px;
  border-radius: 50px;
}

.item .status span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.item .status h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .status.on span {
  background-color: var(--cor-azul);
}

.item .status.off span {
  background-color: var(--cor-vermelho);
}

.item .links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links a.dominio {
  border-right: 2px solid var(--cor-cinza-3);
}

.item .links a {
  text-align: center;
  width: 50%;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  padding: 15px 5px;
  transition: all 0.3s;
}

.item .links a.sem {
  opacity: 0.5;
  pointer-events: none;
}

.item .links a:hover {
  color: var(--cor-azul);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .item .info {
    width: 100%;
    padding: 20px;
  }

  .item .nome h3 {
    font-size: var(--f1);
  }

  .item .nome p {
    font-size: var(--f0);
  }

  .item .status {
    padding: 8px 12px;
  }

  .item .status h3 {
    font-size: var(--f0);
  }

  .item .links {
    flex-direction: column;
  }

  .item .links a.dominio {
    border-bottom: 2px solid var(--cor-cinza-3);
    border-right: none;
  }

  .item .links a {
    width: 100%;
    font-size: var(--f0);
    padding: 13px 5px;
  }
}
</style>
