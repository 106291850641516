import { api } from '@/api/produtor/axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  validarDominioDNS(dominio) {
    const method = 'GET'
    const url = `dns/validar/${dominio}`

    return api(method, url, null, token)
  }
}
