import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberPorHotlink(idArea, idCurso, idModulo, hotlink) {
    const method = 'GET'
    const url = `aulas/hotlink/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${hotlink}`

    return api(method, url, null, token)
  },

  receberPorId(idArea, idCurso, idModulo, idAula) {
    const method = 'GET'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`

    return api(method, url, null, token)
  },

  receberTodos(idArea, idCurso, idModulo) {
    const method = 'GET'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}`

    return api(method, url, null, token)
  },

  adicionar(idArea, idCurso, idModulo, payload) {
    const method = 'POST'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}`
    const dados = payload

    return api(method, url, dados, token)
  },

  duplicar(idArea, idCurso, idModulo, idAula) {
    const method = 'POST'
    const url = `duplicar/aula/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`

    return api(method, url, null, token)
  },

  alterarDados(idArea, idCurso, idModulo, idAula, payload) {
    const method = 'PUT'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`
    const dados = payload

    return api(method, url, dados, token)
  },

  deletar(idArea, idCurso, idModulo, idAula) {
    const method = 'DELETE'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`
    const dados = {}

    return api(method, url, dados, token)
  },

  ordenarPosicoes(idArea, idCurso, idModulo, payload) {
    const method = 'PATCH'
    const url = `aulas/posicao/ordenar/${idProdutor}/${idArea}/${idCurso}/${idModulo}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
