<template>
  <section class="telas" ref="conteudo" :style="conteudoEstilo">
    <div class="conteudo" @mousedown="mouseDown($event, '.conteudo')" @mouseleave="mouseLeave('.conteudo')" @mousemove="mouseMove($event, '.conteudo')" @mouseup="mouseUp('.conteudo')">
      <div class="nome" @click="irParaRota(storeAreas.area?.hotlink)">
        <img v-if="storeAreas.area?.imagemLogo" :src="`${storeAreas.area?.imagemLogo}`" class="logo" />
        <Svgs v-else class="logo" nome="logo-v1" />
        <a :href="`https://mber.live/${storeAreas.area?.hotlink}`" target="_blank" :style="tituloEstilo">mber.live/{{ storeAreas.area?.hotlink }}</a>
      </div>
      <a class="voltar" :href="`/areas/${storeAreas.area?.hotlink}`" :style="bordaEstilo">
        <Svgs nome="setinha" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Voltar</p>
      </a>
      <button :class="{ ativo: selecionado === 'login' }" @click="selecionar('login')" :style="bordaEstilo">
        <Svgs nome="sair" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Tela de Login</p>
      </button>
      <button :class="{ ativo: selecionado === 'senha' }" @click="selecionar('senha')" :style="bordaEstilo">
        <Svgs nome="cadeado" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Tela Alterar Senha</p>
      </button>
      <button :class="{ ativo: selecionado === 'loading' }" @click="selecionar('loading')" :style="bordaEstilo">
        <Svgs nome="loading" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Tela de Loading</p>
      </button>
      <button :class="{ ativo: selecionado === 'menu' }" @click="selecionar('menu')" :style="bordaEstilo">
        <Svgs nome="menu" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Menu da Área</p>
      </button>
      <button :class="{ ativo: selecionado === 'inicio' }" @click="selecionar('inicio')" :style="bordaEstilo">
        <Svgs nome="casa" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Início da Área</p>
      </button>
      <button :class="{ ativo: selecionado === 'curso' }" @click="selecionar('curso')" :style="bordaEstilo">
        <Svgs nome="curso" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Curso da Área</p>
      </button>
      <button :class="{ ativo: selecionado === 'modulo' }" @click="selecionar('modulo')" :style="bordaEstilo">
        <Svgs nome="modulo" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Módulo do Curso</p>
      </button>
      <button :class="{ ativo: selecionado === 'aula' }" @click="selecionar('aula')" :style="bordaEstilo">
        <Svgs nome="play" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Aula do Módulo</p>
      </button>
      <button :class="{ ativo: selecionado === 'perfil' }" @click="selecionar('perfil')" :style="bordaEstilo">
        <Svgs nome="usuario" :style="botoesSvgEstilo" />
        <p :style="tituloEstilo">Perfil do Aluno</p>
      </button>
    </div>
  </section>
  <section class="edicoes" ref="itens">
    <div class="itens" @mousedown="mouseDownDois($event, '.itens')" @mouseleave="mouseLeaveDois('.itens')" @mousemove="mouseMoveDois($event, '.itens')" @mouseup="mouseUpDois('.itens')">
      <component :is="telaAtual" />
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, inject } from 'vue'
import { useStorePersonalizacao, useStoreAreas } from '@stores'
import { helperDragScroll } from '@helpers'
import Svgs from '@svgs'

import SectionLogin from '@components/areaDeMembros/personalizacao/telas/login/partials/SectionEdicoes.vue'
import SectionSenha from '@components/areaDeMembros/personalizacao/telas/senha/partials/SectionEdicoes.vue'
import SectionLoading from '@components/areaDeMembros/personalizacao/telas/loading/partials/SectionEdicoes.vue'
import SectionMenu from '@components/areaDeMembros/personalizacao/telas/menu/partials/SectionEdicoes.vue'
import SectionInicio from '@components/areaDeMembros/personalizacao/telas/inicio/partials/SectionEdicoes.vue'
import SectionCurso from '@components/areaDeMembros/personalizacao/telas/curso/partials/SectionEdicoes.vue'
import SectionModulo from '@components/areaDeMembros/personalizacao/telas/modulo/partials/SectionEdicoes.vue'
import SectionAula from '@components/areaDeMembros/personalizacao/telas/aula/partials/SectionEdicoes.vue'
import SectionPerfil from '@components/areaDeMembros/personalizacao/telas/perfil/partials/SectionEdicoes.vue'

const itens = ref(null)
const conteudo = ref(null)
const emitter = inject('emitter')

const tela = computed(() => storeAreas.area?.personalizacao?.menu || {})

const selecionado = ref('')
const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#252939'
}))

const bordaEstilo = computed(() => ({
  borderColor: tela.value.corIcones || '#252939'
}))

const botoesSvgEstilo = computed(() => ({
  fill: tela.value.corIcones || '#252939'
}))

const telas = {
  login: SectionLogin,
  senha: SectionSenha,
  loading: SectionLoading,
  menu: SectionMenu,
  inicio: SectionInicio,
  curso: SectionCurso,
  modulo: SectionModulo,
  aula: SectionAula,
  perfil: SectionPerfil
}

const telaAtual = computed(() => telas[selecionado.value])

const selecionar = (nome) => {
  selecionado.value = nome
  storePersonalizacao.telaSelecionada = nome
  emitter.emit('mostrarLoading')
}

function irParaRota(hotlink) {
  if (hotlink) {
    const url = `https://mber.live/${hotlink}`
    window.open(url, '_blank')
  }
}

// Conteúdo

function mouseDown(event, classe) {
  helperDragScroll.mouseDown(event, conteudo.value, classe)
}

function mouseLeave(classe) {
  helperDragScroll.mouseLeave(conteudo.value, classe)
}

function mouseUp(classe) {
  helperDragScroll.mouseUp(conteudo.value, classe)
}

function mouseMove(event, classe) {
  helperDragScroll.mouseMove(event, conteudo.value, classe)
}

// Itens

function mouseDownDois(event, classe) {
  helperDragScroll.mouseDown(event, itens.value, classe)
}

function mouseLeaveDois(classe) {
  helperDragScroll.mouseLeave(itens.value, classe)
}

function mouseUpDois(classe) {
  helperDragScroll.mouseUp(itens.value, classe)
}

function mouseMoveDois(event, classe) {
  helperDragScroll.mouseMove(event, itens.value, classe)
}

onMounted(() => {
  selecionar('inicio')
})
</script>

<style>
section.telas {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--cor-branco);
  width: 100%;
  height: 50px;
  z-index: 5;
}

section.telas .conteudo {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 0 20px;
}

section.telas .conteudo::-webkit-scrollbar {
  display: none;
}

section.telas .nome {
  display: flex;
  align-items: center;
}

section.telas .nome img {
  max-width: 100px;
  max-height: 30px;
}

section.telas .nome svg {
  width: 100px;
  max-width: 100px;
}

section.telas .nome a {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  padding: 0 5px 0 5px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

section.telas .nome a:hover {
  opacity: 0.5;
}

section.telas button,
section.telas a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid var(--cor-cinza-2);
  padding: 7px 10px;
  border-radius: 50px;
  white-space: nowrap;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

section.telas button:hover {
  opacity: 0.8;
  transform: scale(0.95);
}

section.telas button p,
section.telas a p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

section.telas button svg,
section.telas a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-preto);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

section.telas a.voltar svg {
  transform: rotate(180deg);
}

section.telas button.ativo {
  opacity: 0.5;
  pointer-events: none;
}

section.telas button.ativo p {
  color: var(--cor-branco-fixo);
}

section.telas button.ativo svg {
  fill: var(--cor-branco-fixo);
}

section.edicoes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 50px;
  left: 0;
  background-color: var(--cor-gelo);
  border-top: 2px solid var(--cor-cinza-2);
  border-bottom: 2px solid var(--cor-cinza-2);
  width: 100%;
  height: 40px;
  z-index: 5;
}

section.edicoes .itens {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 0 20px;
}

section.edicoes .itens::-webkit-scrollbar {
  display: none;
}

section.edicoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-right: 2px solid var(--cor-cinza-2);
  padding: 0 20px;
  white-space: nowrap;
  height: 40px;
  transition: all 0.3s;
}

section.edicoes button:nth-child(1) {
  padding: 0 20px 0 0;
}

section.edicoes button:nth-last-child(1) {
  border-right: none;
  padding: 0 0 0 20px;
}

section.edicoes button.ativo p {
  color: var(--cor-azul-forte);
}

section.edicoes button.ativo {
  pointer-events: none;
}

section.edicoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

section.edicoes button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-preto);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

section.edicoes button:hover p {
  color: var(--cor-cinza);
}
</style>
