function bloquearBodyQuandoAbrirModal() {
  const conteudoHtml = document.body.innerHTML
  const bodyBloqueado = document.body?.classList?.contains('bloquear')
  const modalAberta = conteudoHtml?.includes('modal mostrar')
  const loadingAberto = conteudoHtml?.includes('loading mostrar')

  if ((modalAberta || loadingAberto) && !bodyBloqueado) {
    document.body?.classList?.add('bloquear')
  } else if (!modalAberta && !loadingAberto && bodyBloqueado) {
    document.body?.classList?.remove('bloquear')
  } else {
    return
  }
}

export default { bloquearBodyQuandoAbrirModal }
