import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberEstatisticas() {
    const method = 'GET'
    const url = `painel/${idProdutor}`

    return api(method, url, null, token)
  },
}
