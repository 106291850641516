<template>
  <section class="tela">
    <component :is="telaAtual" />
  </section>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStorePersonalizacao } from '@stores'

import Login from '@components/areaDeMembros/personalizacao/telas/login/Login.vue'
import Senha from '@components/areaDeMembros/personalizacao/telas/senha/Senha.vue'
import Loading from '@components/areaDeMembros/personalizacao/telas/loading/Loading.vue'
import Menu from '@components/areaDeMembros/personalizacao/telas/menu/Menu.vue'
import Inicio from '@components/areaDeMembros/personalizacao/telas/inicio/Inicio.vue'
import Curso from '@components/areaDeMembros/personalizacao/telas/curso/Curso.vue'
import Modulo from '@components/areaDeMembros/personalizacao/telas/modulo/Modulo.vue'
import Aula from '@components/areaDeMembros/personalizacao/telas/aula/Aula.vue'
import Perfil from '@components/areaDeMembros/personalizacao/telas/perfil/Perfil.vue'

const storePersonalizacao = useStorePersonalizacao()

const telas = {
  login: Login,
  senha: Senha,
  loading: Loading,
  menu: Menu,
  inicio: Inicio,
  curso: Curso,
  modulo: Modulo,
  aula: Aula,
  perfil: Perfil
}

const selecionado = ref(storePersonalizacao.telaSelecionada)

const telaAtual = computed(() => telas[selecionado.value])

function atualizarTela() {
  selecionado.value = storePersonalizacao.telaSelecionada
}

watch(
  () => storePersonalizacao.telaSelecionada,
  () => {
    atualizarTela()
  },
  { immediate: true }
)
</script>
