import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberPorHotlink(idArea, idCurso, hotlink) {
    const method = 'GET'
    const url = `modulos/hotlink/${idProdutor}/${idArea}/${idCurso}/${hotlink}`

    return api(method, url, null, token)
  },

  receberPorId(idArea, idCurso, idModulo) {
    const method = 'GET'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}/${idModulo}`

    return api(method, url, null, token)
  },

  receberTodos(idArea, idCurso) {
    const method = 'GET'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}`

    return api(method, url, null, token)
  },

  adicionar(idArea, idCurso, payload) {
    const method = 'POST'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}`
    const dados = payload

    return api(method, url, dados, token)
  },

  duplicar(idArea, idCurso, idModulo) {
    const method = 'POST'
    const url = `duplicar/modulo/${idProdutor}/${idArea}/${idCurso}/${idModulo}`

    return api(method, url, null, token)
  },

  alterarDados(idArea, idCurso, idModulo, payload) {
    const method = 'PUT'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}/${idModulo}`
    const dados = payload

    return api(method, url, dados, token)
  },

  deletar(idArea, idCurso, idModulo) {
    const method = 'DELETE'
    const url = `modulos/${idProdutor}/${idArea}/${idCurso}/${idModulo}`
    const dados = {}

    return api(method, url, dados, token)
  },

  ordenarPosicoes(idArea, idCurso, payload) {
    const method = 'PATCH'
    const url = `modulos/posicao/ordenar/${idProdutor}/${idArea}/${idCurso}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
