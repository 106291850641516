import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiAlunos from '../api/produtor/api-alunos'

export const useStoreAlunos = defineStore('storeAlunos', {
  state: () => {
    return {
      alunos: null,
      aluno: null,
      filtroBusca: '',
      filtroOrdem: '',
      filtroCurso: ''
    }
  },
  actions: {
    async receberTodos(idArea) {
      try {
        const resp = await apiAlunos.receberTodos(idArea)
        this.alunos = resp
        return resp
      } catch (error) {
        this.alunos = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorId(idArea, idAluno) {
      try {
        const resp = await apiAlunos.receberPorId(idArea, idAluno)
        this.aluno = resp
        return true
      } catch (error) {
        this.aluno = null
        return false
      }
    },

    async resetarSenha(idArea, idAluno) {
      try {
        const resp = await apiAlunos.resetarSenha(idArea, idAluno)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async adicionar(idArea, payload) {
      try {
        const resp = await apiAlunos.adicionar(idArea, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarAcesso(idArea, idAluno, idCurso, payload) {
      try {
        const resp = await apiAlunos.alterarAcesso(idArea, idAluno, idCurso, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
