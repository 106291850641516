<template>
  <div class="selectFont">
    <div class="input">
      <Svgs class="icone" nome="lupa" />
      <input type="text" v-model="state.consultaBusca" placeholder="Buscar fonte" @input="buscarFonte" />
    </div>
    <button class="item" :class="{ ativo: fonteSelecionada === fonte.family }" v-for="(fonte, index) in fontesExibidas" :key="`grupo-${index}`" @click="selecionarFonte(fonte.family)">
      <p :style="{ fontFamily: fonte.family }">{{ fonte.family }}</p>
      <span></span>
    </button>
    <button class="carregar" v-if="podeCarregarMais" @click="carregarMaisFontes">Carregar mais</button>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed, watch, watchEffect } from 'vue'
import Svgs from '@svgs'

const props = defineProps({
  fonteInicial: {
    type: String,
    default: 'Inter'
  }
})

const emits = defineEmits(['fonteSelecionada'])

const fonteSelecionada = ref(props.fonteInicial)
const fontes = ref([])

const state = reactive({
  consultaBusca: '',
  indicePagina: 0,
  tamanhoPagina: 20,
  fontesFiltradas: []
})

const API_KEY = 'AIzaSyC2Rumjjhinr8ZRh-4Fsa6bFxWdC6goyzg'

onMounted(() => {
  fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      fontes.value = data.items
      state.fontesFiltradas = fontes.value
    })
    .catch((error) => console.error('Erro ao buscar fontes:', error))
})

const fontesExibidas = computed(() => {
  const inicio = state.indicePagina * state.tamanhoPagina
  const fim = inicio + state.tamanhoPagina
  return state.fontesFiltradas.slice(inicio, fim)
})

function selecionarFonte(fonte) {
  fonteSelecionada.value = fonte
  const url = `https://fonts.googleapis.com/css2?family=${fonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    link.onerror = () => {
      fonteSelecionada.value = ''
    }
    link.onload = () => {
      fonteSelecionada.value = fonte
      emits('fonteSelecionada', { fonte })
    }
    document.head.appendChild(link)
  } else {
    fonteSelecionada.value = fonte
    emits('fonteSelecionada', { fonte })
  }
}

function carregarMaisFontes() {
  state.indicePagina++
}

function buscarFonte() {
  state.indicePagina = 0
  if (state.consultaBusca) {
    state.fontesFiltradas = fontes.value.filter((fonte) => fonte.family.toLowerCase().includes(state.consultaBusca.toLowerCase()))
  } else {
    state.fontesFiltradas = fontes.value
  }
}

const podeCarregarMais = computed(() => {
  return (state.indicePagina + 1) * state.tamanhoPagina < state.fontesFiltradas.length
})

function carregarFontesExibidas() {
  fontesExibidas.value.forEach((fonte) => {
    const url = `https://fonts.googleapis.com/css2?family=${fonte.family.replace(' ', '+')}&display=swap`

    const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

    if (!existingLink) {
      const link = document.createElement('link')
      link.href = url
      link.rel = 'stylesheet'
      document.head.appendChild(link)
    }
  })
}

watch(
  () => props.fonteInicial,
  (novoValor) => {
    fonteSelecionada.value = novoValor
  }
)

watchEffect(() => {
  carregarFontesExibidas()
})
</script>

<style scoped>
.selectFont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input {
  margin: 0 0 20px 0;
}

.itens {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60dvh;
  max-height: 60dvh;
  padding: 120px 0 30px 0;
  overflow-y: scroll;
  z-index: 1;
}

button.item {
  background-color: transparent;
  padding: 10px 20px;
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

button.item.ativo {
  pointer-events: none;
}

button.item.ativo span {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

button.item span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, var(--cor-gelo), var(--cor-cinza-2), var(--cor-gelo));
  background: -webkit-linear-gradient(0deg, var(--cor-gelo), var(--cor-cinza-2), var(--cor-gelo));
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: all 0.5s;
}

button.item:hover img {
  opacity: 0.4;
}

button.item:hover p {
  opacity: 0.6;
}

button.item p {
  font-size: var(--f3);
  color: var(--cor-preto);
  z-index: 2;
  position: relative;
  transition: all 0.3s;
}

button.item img {
  max-height: 13px;
  max-width: 180px;
  z-index: 2;
  position: relative;
  transition: all 0.3s;
}

button.carregar {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  padding: 15px 25px;
  border-radius: 50px;
  background-color: var(--cor-gelo);
  border: 2px solid var(--cor-cinza-2);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

button.carregar:hover {
  color: var(--cor-roxo);
  border: 2px solid var(--cor-roxo);
  transform: scale(1.05);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  .lista {
    bottom: 20px;
    right: 20px;
    width: 100%;
    max-width: 300px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }

  .lista button.item p {
    font-size: var(--f2);
  }
}
</style>
