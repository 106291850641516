<template>
  <Modal nome="editarPosicaoAulas">
    <div class="item" v-for="(aula, index) in state.listaAulas" :key="aula">
      <div class="posicao">
        <p>{{ aula.posicao }}</p>
      </div>
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${aula.imagemCapa || ''}')`"></div>
        </div>
      </div>
      <div class="modulo">
        <h3>{{ formatarTitulo(aula.nome) }}</h3>
        <p>{{ formatarTitulo(aula.descricao) }}</p>
      </div>
      <button class="mover cima" @click="moverParaCima(index)" :disabled="index === 0">
        <Svgs nome="seta" />
      </button>
      <button class="mover baixo" @click="moverParaBaixo(index)" :disabled="index === state.listaAulas?.length - 1">
        <Svgs nome="seta" />
      </button>
    </div>

    <SalvarModal nome="Salvar Posição" @click="salvarPosicoes" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive } from 'vue'
import { useStoreModal, useStoreCursos, useStoreAreas, useStoreModulos, useStoreAulas } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'

const storeAulas = useStoreAulas()
const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const emitter = inject('emitter')

const state = reactive({
  listaAulas: []
})

function moverParaCima(index) {
  if (index > 0) {
    const item = state.listaAulas[index]
    state.listaAulas.splice(index, 1)
    state.listaAulas.splice(index - 1, 0, item)
  }
}

function moverParaBaixo(index) {
  if (index < state.listaAulas.length - 1) {
    const item = state.listaAulas[index]
    state.listaAulas.splice(index, 1)
    state.listaAulas.splice(index + 1, 0, item)
  }
}

function formatarTitulo(titulo) {
  if (titulo.length <= 35) {
    return titulo
  }
  return titulo.substring(0, 35) + '...'
}

async function salvarPosicoes() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id

  const payload = {
    ids: state.listaAulas.map((aula) => aula._id)
  }

  const posicoesOk = await storeAulas.ordenarPosicoes(idArea, idCurso, idModulo, payload)
  if (posicoesOk !== true) return

  emitter.emit('atualizarAulas')
  storeModal.fecharModal('editarPosicaoAulas')
  limparCampos()
}

function carregarListaAulas() {
  if (storeAulas.aulas) {
    state.listaAulas = JSON.parse(JSON.stringify(storeAulas.aulas))
  }
}

function limparCampos() {
  state.listaAulas = []
}

onMounted(() => {
  emitter.on('carregarCampos', carregarListaAulas)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  margin: 0 0 10px 0;
  padding: 10px 100px 10px 15px;
  position: relative;
}

.item .posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.item .posicao p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
}

.item .capa {
  width: 50px;
  min-width: 50px;
}

.item .capa .tamanho {
  position: relative;
  padding-bottom: 100%;
  width: 100%;
}

.item .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.item .modulo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 10px;
}

.item .modulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
}

.item .modulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item button.mover {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--cor-cinza-4);
  border: 2px solid var(--cor-cinza-4);
  transition: all 0.3s;
}

.item button.mover:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.item button.mover.cima {
  right: 60px;
}

.item button.mover:hover {
  border: 2px solid var(--cor-laranja);
  transform: scale(1.05);
}

.item button.mover.baixo {
  right: 15px;
}

.item button.mover svg {
  width: 15px;
  min-width: 15px;
}

.item button.mover.cima svg {
  fill: var(--cor-branco);
  transform: rotate(-90deg);
}

.item button.mover.baixo svg {
  fill: var(--cor-branco);
  transform: rotate(90deg);
}

button.salvar {
  margin: 10px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .item {
    padding: 10px 90px 10px 15px;
  }

  .item .posicao {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin: 0 8px 0 0;
  }

  .item .posicao p {
    font-size: var(--f1);
  }

  .item .capa {
    width: 40px;
    min-width: 40px;
  }

  .item .modulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 0 10px;
  }

  .item .modulo p {
    font-size: var(--f1);
  }

  .item button.mover.cima {
    right: 55px;
  }
}
</style>
