<template>
  <div class="loading">
    <div class="container">
      <div class="dot" v-for="n in 8" :key="n"></div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
div.loading {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 90px 0 0 0;
  background-color: var(--cor-branco);
  background: linear-gradient(0deg, var(--cor-gelo), var(--cor-branco));
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@keyframes ocultarLoading {
  0% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  70% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

.loading.mostrar {
  display: flex;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  animation: ocultarLoading 1s linear;
}

.container {
  --loading-size: 50px;
  --loading-color: var(--cor-preto); /* Customize this color as needed */
  --loading-speed: 0.9s;
  --loading-center: calc(var(--loading-size) / 2 - var(--loading-size) / 5 / 2);
  animation: rotate calc(var(--loading-speed) * 3) linear infinite;
  height: var(--loading-size);
  position: relative;
  width: var(--loading-size);
}

.container,
.dot {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.dot {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.dot:before {
  animation: oscillate var(--loading-speed) ease-in-out infinite alternate;
  background-color: var(--loading-color);
  border-radius: 50%;
  content: '';
  height: 20%;
  transition: background-color 0.3s ease;
  width: 20%;
}

.dot:first-child:before {
  transform: translateX(var(--loading-center));
}

.dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot:nth-child(2):before {
  animation-delay: calc(var(--loading-speed) * -0.125);
  transform: translateX(var(--loading-center));
}

.dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot:nth-child(3):before {
  animation-delay: calc(var(--loading-speed) * -0.25);
  transform: translateX(var(--loading-center));
}

.dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot:nth-child(4):before {
  animation-delay: calc(var(--loading-speed) * -0.375);
  transform: translateX(var(--loading-center));
}

.dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot:nth-child(5):before {
  animation-delay: calc(var(--loading-speed) * -0.5);
  transform: translateX(var(--loading-center));
}

.dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot:nth-child(6):before {
  animation-delay: calc(var(--loading-speed) * -0.625);
  transform: translateX(var(--loading-center));
}

.dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot:nth-child(7):before {
  animation-delay: calc(var(--loading-speed) * -0.75);
  transform: translateX(var(--loading-center));
}

.dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot:nth-child(8):before {
  animation-delay: calc(var(--loading-speed) * -0.875);
  transform: translateX(var(--loading-center));
}

@keyframes oscillate {
  0% {
    opacity: 0.25;
    transform: translateX(var(--loading-center)) scale(0);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  div.loading {
    border-left: none;
    padding: 60px 0 0 0;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
</style>
