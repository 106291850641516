import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiAreas from '../api/produtor/api-areas'

export const useStoreAreas = defineStore('storeAreas', {
  state: () => {
    return {
      areas: null,
      area: null
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiAreas.receberTodos()
        this.areas = resp
        return resp
      } catch (error) {
        this.areas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(hotlink) {
      try {
        const resp = await apiAreas.receberPorHotlink(hotlink)
        this.area = resp
        return true
      } catch (error) {
        this.area = null
        return false
      }
    },

    async receberPorId(idArea) {
      try {
        const resp = await apiAreas.receberPorId(idArea)
        this.area = resp
        return true
      } catch (error) {
        this.area = null
        return false
      }
    },

    async adicionar(payload) {
      try {
        const resp = await apiAreas.adicionar(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async duplicar(idArea) {
      try {
        const resp = await apiAreas.duplicar(idArea)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDominio(payload) {
      try {
        const resp = await apiAreas.alterarDominio(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async removerDominio(payload) {
      try {
        const resp = await apiAreas.removerDominio(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiAreas.alterarDados(payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idArea) {
      try {
        const resp = await apiAreas.deletar(idArea)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
