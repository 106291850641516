import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {

  receberTodos(idArea, idCurso, idModulo, idAula) {
    const method = 'GET'
    const url = `comentarios/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`

    return api(method, url, null, token)
  },

  comentar(idArea, idCurso, idModulo, idAula, payload) {
    const method = 'POST'
    const url = `comentarios/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`
    const dados = payload

    return api(method, url, dados, token)
  },

  alterarDados(idArea, idCurso, idModulo, idAula, payload) {
    const method = 'PUT'
    const url = `aulas/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}`
    const dados = payload

    return api(method, url, dados, token)
  },

  deletar(idArea, idCurso, idModulo, idAula, idComentario) {
    const method = 'DELETE'
    const url = `comentarios/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idComentario}`
    const dados = {}

    return api(method, url, dados, token)
  },

  responder(idArea, idCurso, idModulo, idAula, idComentario, payload) {
    const method = "PATCH"
    const url = `comentarios/responder/${idProdutor}/${idArea}/${idCurso}/${idModulo}/${idAula}/${idComentario}`
    const dados = payload

    return api(method, url, dados, token)
  },
}
