<template>
  <section class="aula" :style="conteudoEstilo">
    <div class="menu">
      <h3>Menu</h3>
      <p>
        Personalize na aba
        <Svgs nome="menu" />
        Menu da Área
      </p>
    </div>
    <div class="tela">
      <div class="conteudo">
        <div class="principal">
          <!-- Vídeo -->
          <div class="video">
            <div class="tamanho">
              <div class="iframe">
                <Svgs nome="play" />
                <p>Seu vídeo ficará aqui</p>
              </div>
            </div>
          </div>
          <!-- Opções -->
          <div class="opcoes">
            <div class="posicao" :style="posicaoFundoEstilo">
              <p :style="posicaoTextoEstilo">3º</p>
            </div>
            <div class="duracao">
              <Svgs nome="relogio" :style="duracaoSvgEstilo" />
              <p :style="duracaoTextoEstilo">52 minutos</p>
            </div>
          </div>
          <!-- Nome -->
          <div class="nome">
            <h3 :style="tituloEstilo">Nome da Aula</h3>
            <p :style="descricaoEstilo">Essa é a descrição da aula</p>
          </div>
          <!-- Links da Aula -->
          <div class="links">
            <h3 :style="tituloEstilo">Links na Aula</h3>
            <a>
              <Svgs :style="linkSvgEstilo" nome="link" />
              <p :style="linkTextoEstilo">Perfil Instagram</p>
            </a>
          </div>
          <!-- Arqivos da Aula -->
          <div class="arquivos">
            <h3 :style="tituloEstilo">Arquivos na Aula</h3>
            <a>
              <Svgs :style="arquivoSvgEstilo" nome="arquivo" />
              <p :style="arquivoTextoEstilo">Banner</p>
            </a>
          </div>
        </div>

        <div class="comentarios">
          <div class="comentar">
            <div class="input">
              <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
              <textarea :style="textareaEstilo" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea></textarea>
            </div>
            <div class="botoes">
              <button class="cancelar" :style="textoDataComentarioEstilo">Cancelar</button>
              <button class="enviar" :style="botaoComentarEstilo">Comentar</button>
            </div>
          </div>

          <div class="comentario" v-for="comentario in state.comentarios" :key="comentario">
            <div class="foto" :style="`background-image: url('${comentario.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
            <div class="nome">
              <h3 :style="textoComentarios">{{ comentario.pessoa }}</h3>
              <p :style="textoDataComentarioEstilo">{{ comentario.data }}</p>
            </div>
            <div class="texto">
              <p :style="textoComentarios">{{ comentario.texto }}</p>
            </div>

            <button class="responder" :style="botaoResponderEstilo">Responder</button>

            <div class="resposta" v-for="resposta in comentario.respostas" :key="resposta">
              <div class="foto" :style="`background-image: url('${resposta.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
              <div class="nome">
                <h3 :style="textoComentarios">{{ resposta.pessoa }}</h3>
                <p :style="textoDataComentarioEstilo">{{ resposta.data }}</p>
              </div>
              <div class="texto">
                <p :style="textoComentarios">{{ resposta.texto }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="aulas">
        <div class="item" v-for="aula in state.aulas" :key="aula">
          <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/default/background-vertical.svg')"></div>
          <div class="info" :style="aulaEstilo">
            <h5 :style="posicaoAulaEstilo">{{ aula.posicao }}º</h5>
            <div class="nome">
              <p :style="duracaoAulaEstilo">{{ aula.duracao }} min</p>
              <h3 :style="textoAulaEstilo">{{ aula.nome }}</h3>
            </div>
            <div class="botoes">
              <button class="acessar" :style="fundoBotaoAulaEstilo">
                <Svgs :style="svgBotaoAulaEstilo" nome="seta" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { useStorePersonalizacao, useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storePersonalizacao = useStorePersonalizacao()

const state = reactive({
  aulas: [
    {
      posicao: '1',
      duracao: '10',
      nome: 'Introdução à Plataforma'
    },
    {
      posicao: '2',
      duracao: '15',
      nome: 'Configuração Inicial'
    },
    {
      posicao: '3',
      duracao: '20',
      nome: 'Gerenciamento de Membros'
    },
    {
      posicao: '4',
      duracao: '25',
      nome: 'Personalização da Área'
    },
    {
      posicao: '5',
      duracao: '30',
      nome: 'Integrações e Automação'
    },
    {
      posicao: '6',
      duracao: '12',
      nome: 'Análise de Dados'
    },
    {
      posicao: '7',
      duracao: '18',
      nome: 'Melhores Práticas'
    }
  ],
  comentarios: [
    {
      imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
      pessoa: 'Ana',
      data: 'há 5 minutos',
      texto: 'A introdução foi muito clara e me ajudou a entender a plataforma.',
      respostas: [
        {
          imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
          pessoa: 'João',
          data: 'há 2 minutos',
          texto: 'Também achei! Ansioso para ver as próximas aulas.'
        },
        {
          imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
          pessoa: 'Maria',
          data: 'há 1 minuto',
          texto: 'A introdução realmente esclareceu muitas dúvidas.'
        }
      ]
    },
    {
      imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
      pessoa: 'Carlos',
      data: 'há 15 minutos',
      texto: 'A aula sobre gerenciamento de membros é essencial para quem está começando.',
      respostas: [
        {
          imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
          pessoa: 'Fernanda',
          data: 'há 10 minutos',
          texto: 'Concordo, Carlos. As dicas foram muito úteis!'
        }
      ]
    },
    {
      imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
      pessoa: 'Lucas',
      data: 'há 20 minutos',
      texto: 'As integrações são fáceis de configurar graças às instruções claras.',
      respostas: [
        {
          imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
          pessoa: 'Mariana',
          data: 'há 18 minutos',
          texto: 'Sim, agora minha área de membros está funcionando perfeitamente!'
        },
        {
          imagemPerfil: 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png',
          pessoa: 'Pedro',
          data: 'há 17 minutos',
          texto: 'As automações economizam muito tempo.'
        }
      ]
    }
  ]
})

const tela = computed(() => storePersonalizacao.personalizacao?.telaAula || {})

// Fundo

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

// Principal

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#262836'
}))

const descricaoEstilo = computed(() => ({
  color: tela.value.corDescricao || '#262836'
}))

const duracaoSvgEstilo = computed(() => ({
  fill: tela.value.corDuracao || '#E1E3EB'
}))

const duracaoTextoEstilo = computed(() => ({
  color: tela.value.corDuracao || '#E1E3EB'
}))

const linkSvgEstilo = computed(() => ({
  fill: tela.value.corLinks || '#E1E3EB'
}))

const linkTextoEstilo = computed(() => ({
  color: tela.value.corLinks || '#E1E3EB'
}))

const arquivoSvgEstilo = computed(() => ({
  fill: tela.value.corArquivos || '#E1E3EB'
}))

const arquivoTextoEstilo = computed(() => ({
  color: tela.value.corArquivos || '#E1E3EB'
}))

const posicaoFundoEstilo = computed(() => ({
  backgroundColor: tela.value.corPosicaoFundo || '#E1E3EB'
}))

const posicaoTextoEstilo = computed(() => ({
  color: tela.value.corPosicaoTexto || '#FFFFFF'
}))

// Comentários

const textareaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampoComentario || '#FFFFFF',
  color: tela.value.corTextoCampoComentario || '#262836'
}))

const botaoComentarEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotaoComentar || '#262836',
  color: tela.value.corTextoBotaoComentar || '#FFFFFF'
}))

const botaoResponderEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotaoResponder || '#262836',
  color: tela.value.corTextoBotaoResponder || '#FFFFFF'
}))

const textoComentarios = computed(() => ({
  color: tela.value.corTextosComentarios || '#262836'
}))

const textoDataComentarioEstilo = computed(() => ({
  color: tela.value.corTextoDataComentario || '#262836'
}))

// Aulas

const aulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoAula || '#FFFFFF'
}))

const duracaoAulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoDuracaoAula || '#262836',
  color: tela.value.corTextoDuracaoAula || '#FFFFFF'
}))

const posicaoAulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoPosicaoAula || '#262836',
  color: tela.value.corTextoPosicaoAula || '#FFFFFF'
}))

const fundoBotaoAulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotaoAula || '#262836'
}))

const svgBotaoAulaEstilo = computed(() => ({
  fill: tela.value.corSvgBotaoAula || '#FFFFFF'
}))

const textoAulaEstilo = computed(() => ({
  color: tela.value.corTextoAula || '#262836'
}))

function atualizarFonte() {
  const fontFamily = tela.value.fonte || 'Inter'
  document.documentElement.style.setProperty('--fonte-tela', fontFamily)

  const url = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}&display=swap`

  const linkExistente = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!linkExistente) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watch(
  () => storePersonalizacao.personalizacao?.telaAula,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 30px 30px 330px;
  position: relative;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-gelo);
  width: 300px;
  height: calc(100dvh - 90px);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.menu h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  margin: 0 0 10px 0;
}

.menu p {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

.menu p svg {
  width: 11px;
  min-width: 11px;
  fill: var(--cor-cinza);
  margin: 0 5px;
}

.tela {
  display: flex;
  width: 100%;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
}

.principal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.principal .video {
  width: 100%;
}

.principal .video .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.principal .video .tamanho .iframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  border-radius: 10px;
}

.principal .video .tamanho .iframe svg {
  width: 40px;
  min-width: 40px;
  fill: #ffffff;
  margin: 0 0 20px 0;
}

.principal .video .tamanho .iframe p {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #ffffff;
}

.principal .opcoes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
}

.principal .opcoes .posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #262836;
}

.principal .opcoes .posicao p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
}

.principal .opcoes .duracao {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}

.principal .opcoes .duracao svg {
  width: 15px;
  min-width: 15px;
  fill: #64677c;
  margin: 0 5px 0 0;
}

.principal .opcoes .duracao p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #64677c;
}

.principal .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 10px 0;
}

.principal .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
  line-height: 1.5;
}

.principal .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.principal .links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0 0 0;
}

.principal .links h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  width: 100%;
  text-align: left;
  margin: 0 0 10px 0;
}

.principal .links a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 10px 10px 0;
}

.principal .links a svg {
  width: 15px;
  min-width: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.principal .links a p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  transition: all 0.3s;
}

.principal .links a:hover p {
  opacity: 0.7;
}

.principal .arquivos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0 0 0;
}

.principal .arquivos h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  width: 100%;
  text-align: left;
  margin: 0 0 10px 0;
}

.principal .arquivos a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 10px 10px 0;
}

.principal .arquivos a svg {
  width: 15px;
  min-width: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.principal .arquivos a p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  transition: all 0.3s;
}

.principal .arquivos a:hover p {
  opacity: 0.6;
}

.comentarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 30px 0 20px 0;
}

.comentarios .comentar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.comentarios .input {
  align-items: flex-start;
}

.comentarios .input textarea {
  padding: 20px;
  font-family: var(--fonte-tela);
  background-color: #ffffff;
  color: #262836;
  border: none;
}

.comentarios .input .foto {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 15px 20px 0 0;
}

.comentarios .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0 0 0;
}

.comentarios .botoes button.enviar {
  font-size: var(--f2);
  font-family: var(--fonte-tela);
  padding: 12px 20px;
  border-radius: 50px;
  color: #ffffff;
  background-color: #262836;
  transition: all 0.3s;
}

.comentarios .botoes button.enviar:hover {
  opacity: 0.7;
}

.comentarios .botoes button.cancelar {
  font-size: var(--f2);
  font-family: var(--fonte-tela);
  color: #84879c;
  background-color: transparent;
  padding: 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.comentarios .botoes button.cancelar:hover {
  opacity: 0.7;
}

.comentarios .respondendo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0 0 0;
}

.comentarios .respondendo .input {
  padding: 0 0 0 50px;
}

.comentarios .comentario {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 55px;
  margin: 40px 0 0 0;
  position: relative;
}

.comentarios .comentario .foto {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.comentarios .comentario .nome {
  display: flex;
  align-items: center;
}

.comentarios .comentario .nome h4 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 0 5px 0 0;
}

.comentarios .comentario .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 0 5px 0 0;
}

.comentarios .comentario .nome.produtor h3 {
  color: var(--cor-verde);
}

.comentarios .comentario .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #84879c;
}

.comentarios .comentario .texto {
  display: flex;
  padding: 5px 0 0 0;
}

.comentarios .comentario .texto p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
}

.comentarios .comentario button.responder {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  background-color: #ffffff;
  padding: 8px 15px;
  border-radius: 50px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.comentarios .comentario button.responder:hover {
  transform: scale(1.1);
}

.comentarios .resposta {
  position: relative;
  padding: 0 0 0 45px;
  margin: 30px 0 0 0;
}

.comentarios .resposta .foto {
  width: 35px;
  height: 35px;
}

.comentarios .resposta .nome h3 {
  font-size: var(--f1);
}

.comentarios .resposta .nome h4 {
  font-size: var(--f1);
}

.comentarios .resposta .texto p {
  font-size: var(--f1);
}

.aulas {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0 0 0 20px;
}

.aulas .item {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 80px;
  margin: 0 0 10px 0;
  border-radius: 10px;
}

.aulas .item .capa {
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  width: 140px;
  min-width: 140px;
}

.aulas .item .info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0 10px 10px 0;
  padding: 20px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.aulas .item .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #dfe3ed;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  text-align: center;
}

.aulas .item .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 10px;
}

.aulas .item .info .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
}

.aulas .item .info .nome p {
  display: flex;
  position: absolute;
  padding: 3px 5px;
  border-radius: 3px;
  bottom: 5px;
  left: -55px;
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  line-height: 1;
  color: #ffffff;
  background-color: #262836;
}

.aulas .item .info .botoes {
  display: flex;
  flex-direction: column;
}

.aulas .item .info .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #262836;
  padding: 9px;
  border-radius: 10px;
  transition: all 0.3s;
}

.aulas .item .info .botoes button:hover {
  transform: scale(1.1);
}

.aulas .item .info .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: #ffffff;
}

@media screen and (max-width: 1000px) {
  section.aula {
    padding: 30px 30px 30px 30px;
  }

  .menu {
    display: none;
  }

  .tela {
    flex-direction: column;
  }

  .conteudo {
    width: 100%;
  }

  .principal .video .tamanho .iframe svg {
    width: 30px;
    min-width: 30px;
    margin: 0 0 15px 0;
  }

  .principal .video .tamanho .iframe p {
    font-size: var(--f2);
  }

  .principal .opcoes {
    margin: 15px 0 0 0;
  }

  .principal .opcoes .posicao {
    width: 35px;
    height: 35px;
  }

  .principal .opcoes .posicao p {
    font-size: var(--f1);
  }

  .principal .opcoes .duracao {
    margin: 0 0 0 15px;
  }

  .principal .opcoes .duracao svg {
    width: 13px;
    min-width: 13px;
  }

  .principal .opcoes .duracao p {
    font-size: var(--f1);
  }

  .principal .nome h3 {
    font-size: var(--f2);
  }

  .principal .nome p {
    font-size: var(--f1);
  }

  .principal .links {
    padding: 15px 0 0 0;
  }

  .principal .links h3 {
    font-size: var(--f1);
  }

  .principal .links a svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .principal .links a p {
    font-size: var(--f1);
  }

  .principal .arquivos {
    padding: 15px 0 0 0;
  }

  .principal .arquivos h3 {
    font-size: var(--f1);
  }

  .principal .arquivos a svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .principal .arquivos a p {
    font-size: var(--f1);
  }

  .comentarios {
    padding: 20px 0 20px 0;
  }

  .comentarios .input textarea {
    padding: 15px;
  }

  .comentarios .input .foto {
    width: 35px;
    height: 35px;
    min-width: 35px;
    margin: 15px 15px 0 0;
  }

  .comentarios .botoes button.enviar {
    font-size: var(--f1);
  }

  .comentarios .botoes button.cancelar {
    font-size: var(--f1);
  }

  .comentarios .botoes button.cancelar:hover {
    opacity: 0.7;
  }

  .comentarios .comentario {
    padding: 0 0 0 50px;
    margin: 35px 0 0 0;
  }

  .comentarios .comentario .foto {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }

  .comentarios .resposta {
    padding: 0 0 0 40px;
    margin: 25px 0 0 0;
  }

  .comentarios .resposta .foto {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }

  .aulas {
    width: 100%;
    padding: 20px 0 0 0;
  }

  .aulas .item {
    min-height: 70px;
  }

  .aulas .item .capa {
    width: 110px;
    min-width: 110px;
  }

  .aulas .item .info {
    padding: 15px;
  }

  .aulas .item .info h5 {
    width: 25px;
    height: 25px;
    font-size: var(--f1);
  }

  .aulas .item .info .nome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    padding: 0 20px 0 10px;
  }

  .aulas .item .info .nome h3 {
    font-size: var(--f1);
  }
}
</style>
