<template>
  <div>
    <input type="file" accept=".png, .jpeg, .jpg, .webp" hidden @change="pegarArquivo">
  </div>
</template>

<script setup>
import { inject, getCurrentInstance } from 'vue'
import apiUpload from '@/api/upload/api-upload.js'
const currentInstance = getCurrentInstance()

const props = defineProps({
  dados: Object
})

const emitter = inject('emitter')
const emit = defineEmits(['atualizarDadosImagem'])

function validarExtensoes(mimetype) {
  const extensoesAutorizados = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/webp'
  ]

  if (!extensoesAutorizados.includes(mimetype)) {
    const mensagem = 'A imagem deve estar no formato .png, .jpeg, .jpg, ou .webp'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return false
  }
  return true
}

function validarTamanho(size) {
  const MB2 = 2097152

  if (size > MB2) {
    const mensagem = 'A imagem deve ter no máximo 2MB.'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return false
  }

  return true
}

function enviarImagem() {
  const elemento = currentInstance?.proxy?.$el
  const input = elemento.querySelector(`input[type="file"]`)
  input.click()
}

function converterFormData(arquivo, maxWidth = false) {
  const formData = new window.FormData()

  formData.append('arquivo', arquivo)
  if (maxWidth) formData.append('maxWidth', maxWidth)

  return formData
}

function pegarArquivo() {
  const elemento = currentInstance?.proxy?.$el
  const input = elemento.querySelector(`input[type="file"]`)
  const file = input.files[0]
  if (!file) return

  const size = file.size
  const mimetype = file.type

  if (!validarExtensoes(mimetype)) return
  if (!validarTamanho(size)) return

  const tamanhoMaximo = props.dados.tamanhoMaximo || false
  const formData = converterFormData(file, tamanhoMaximo)
  emit('atualizarDadosImagem', { desabilitarBotao: true })

  apiUpload.uploadImagem(formData).then(resp => {
    const url = resp?.url
    emit('atualizarDadosImagem', { urlImagem: url, desabilitarBotao: false })
  })
    .catch(erro => {
      emit('atualizarDadosImagem', { desabilitarBotao: false })
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: erro })
    })
}

defineExpose({ enviarImagem })
</script>
