import { defineStore } from 'pinia'

export const useStoreQuadrosEscuro = defineStore('storeQuadrosEscuro', {
  state: () => ({
    personalizacao: {
      telaLogin: {
        layout: 'tres',
        fonte: 'Inter',
        corFundo: '#000000',
        corQuadro: '#171717',
        corTitulo: '#FFFFFF',
        corTextoBotao: '#FFFFFF',
        corFundoBotao: '',
        corLabels: '#FFFFFF',
        corFundoCampos: '#232423',
        corTextoCampos: '#FFFFFF',
        corBotoesExtras: '#FFFFFF',
        textoTitulo: '',
        textoBotaoEntrar: 'Entrar Agora',
        textoBotaoExtraUm: 'Suporte Whatsapp',
        textoBotaoExtraDois: 'Perfil Instagram',
        imagemLogo: '',
        imagemFundo: '',
        linkBotaoExtraUm: 'https://whatsapp.com',
        linkBotaoExtraDois: 'https://instagram.com'
      },
      telaAlterarSenha: {
        layout: 'tres',
        fonte: 'Inter',
        corFundo: '#000000',
        corQuadro: '#171717',
        corTitulo: '#FFFFFF',
        corTextoBotao: '#FFFFFF',
        corFundoBotao: '',
        corLabels: '#FFFFFF',
        corFundoCampos: '#232423',
        corTextoCampos: '#FFFFFF',
        corBotoesExtras: '#FFFFFF',
        textoTitulo: '',
        textoBotaoAlterar: 'Recuperar Senha',
        textoBotaoExtra: 'Suporte Whatsapp',
        imagemLogo: '',
        imagemFundo: '',
        linkBotaoExtra: 'https://whatsapp.com'
      },
      telaLoading: {
        fonte: 'Inter',
        corFundo: '#000000',
        corTitulo: '#FFFFFF',
        corLoading: '',
        textoTitulo: 'Carregando seu acesso e conteúdos...',
        imagemLogo: '',
        imagemFundo: ''
      },
      telaInicio: {
        layout: 'dois',
        fonte: 'Inter',
        corFundo: '#000000',
        corQuadro: '#171717',
        corTextos: '#FFFFFF',
        corTextosDois: '#868686',
        corFundoBotao: '',
        corTextoCapa: '#FFFFFF',
        corTextoBotao: '#FFFFFF',
        corProgressoFundo: '#292929',
        corProgressoAtual: '',
        textoCapaTitulo: '',
        textoCapaDescricao: 'Essa é a sua área de estudos',
        textoCapaBotao: 'Comece por Aqui',
        textoBotao: 'Ver módulos',
        textoAulas: 'Seu progresso',
        imagemBannerDesktop: '',
        imagemBannerMobile: '',
        imagemCapaDesktop: '',
        imagemCapaMobile: '',
        linkBanner: '',
        linkCapa: ''
      },
      telaCurso: {
        layout: 'dois',
        fonte: 'Inter',
        corFundo: '#000000',
        corQuadro: '#171717',
        corTitulos: '#FFFFFF',
        corTags: '#797979',
        corFundoBotao: '',
        corTextoBotao: '#FFFFFF',
        corPosicaoTexto: '#FFFFFF',
        corPosicaoFundo: '#323233',
        corCaminho: '',
        textoBotao: 'Ver aulas',
        imagemFundo: ''
      },
      telaModulo: {
        layout: 'tres',
        fonte: 'Inter',
        corFundo: '#000000',
        corQuadro: '#171717',
        corTitulos: '#FFFFFF',
        corTags: '#797979',
        corFundoBotao: '',
        corTextoBotao: '#FFFFFF',
        corPosicaoTexto: '#FFFFFF',
        corPosicaoFundo: '#323233',
        corCaminho: '',
        textoBotao: 'Assistir Aula',
        imagemFundo: ''
      },
      telaAula: {
        fonte: 'Inter',
        corFundo: '#000000',
        corTitulo: '#FFFFFF',
        corDescricao: '#BFC0C0',
        corDuracao: '#888989',
        corLinks: '',
        corArquivos: '',
        corPosicaoTexto: '#FFFFFF',
        corPosicaoFundo: '#171717',
        corFundoCampoComentario: '#171717',
        corTextoCampoComentario: '#FFFFFF',
        corTextosComentarios: '#ffffff',
        corTextoBotaoComentar: '#FFFFFF',
        corFundoBotaoComentar: '',
        corTextoBotaoResponder: '#999999',
        corFundoBotaoResponder: '#171717',
        corTextoDataComentario: '#949494',
        corFundoAula: '#171717',
        corTextoAula: '#FFFFFF',
        corSvgBotaoAula: '#ffffff',
        corFundoBotaoAula: '',
        corTextoPosicaoAula: '#FFFFFF',
        corFundoPosicaoAula: '#383838',
        corTextoDuracaoAula: '#FFFFFF',
        corFundoDuracaoAula: '#000000'
      },
      telaPerfil: {
        layout: '',
        fonte: 'Inter',
        corFundo: '#000000',
        corNome: '#FFFFFF',
        corEmail: '#959595',
        corBotoesFundo: '#171717',
        corBotoesTexto: '#FFFFFF',
        imagemCapa: ''
      },
      menu: {
        layout: '',
        fonte: 'Inter',
        corFundo: '#171717',
        corTitulo: '#FFFFFF',
        corBotoes: '#FFFFFF',
        corIcones: '',
        textoTitulo: '',
        textoBotaoExtraUm: 'Suporte Whatsapp',
        textoBotaoExtraDois: 'Perfil Instagram',
        textoBotaoExtraTres: 'Canal Youtube',
        imagemLogo: '',
        imagemBotaoExtraUm: '',
        imagemBotaoExtraDois: '',
        imagemBotaoExtraTres: '',
        linkBotaoExtraUm: 'https://whatsapp.com',
        linkBotaoExtraDois: 'https://instagram.com',
        linkBotaoExtraTres: 'https://youtube.com'
      }
    }
  })
})
