<template>
  <section class="apresentacao">
    <div class="quadro" @click="router.push(`/areas/${storeAreas.area?.hotlink}/personalizacao`)">
      <div class="icone">
        <Svgs nome="editar" />
      </div>
      <div class="nome">
        <h3>Personalização Full</h3>
        <p>Altere cores, identidade e design</p>
      </div>
    </div>

    <div class="quadro cursos" @click="router.push(`/areas/${storeAreas.area?.hotlink}/cursos`)">
      <div class="icone">
        <Svgs nome="play" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeCursos }}</h3>
        <p>Cursos cadastrados na sua área</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAreas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const router = useRouter()

const quantidadeCursos = computed(() => {
  const quantidade = storeAreas.area?.quantidadeCursos || 0
  return `${quantidade} ${quantidade === 1 ? 'curso' : 'cursos'}`
})
</script>

<style scoped>
section.apresentacao {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 8px);
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  transition: all 0.3s;
}

.quadro:hover {
  border: 1px solid var(--cor-laranja);
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.quadro .icone svg {
  width: 35px;
  min-width: 35px;
  fill: var(--cor-branco);
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 20px;
}

.quadro .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

@media screen and (max-width: 1000px) {
  section.apresentacao {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .area {
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .area .capa {
    max-width: 130px;
  }

  .area .nome {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 10px;
  }

  .area .nome h3 {
    font-size: var(--f1);
  }

  .area .nome p {
    font-size: var(--f1);
    margin: 8px 0 8px 0;
  }

  .area .nome a {
    font-size: var(--f1);
  }

  .area .nome a svg {
    width: 8px;
    min-width: 8px;
  }

  .quadro {
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .quadro .icone {
    width: 60px;
    height: 60px;
    max-width: 60px;
  }

  .quadro .icone svg {
    width: 25px;
    min-width: 25px;
  }

  .quadro .nome {
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
    margin: 0 0 5px 0;
  }

  .quadro .nome p {
    font-size: var(--f1);
    max-width: 300px;
  }

  .quadro button {
    padding: 10px 10px 10px 15px;
    font-size: var(--f1);
  }

  .quadro button svg {
    width: 12px;
    min-width: 12px;
  }
}
</style>
