<template>
  <section class="lista">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar área..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
        </select>
      </div>
    </div>
    <div class="itens">
      <div class="item" v-for="area in areasFiltradas" :key="area._id">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="`background-image: url('${area.imagemCapa || 'https://arquivos.unicpages.com/imagens/app/capa-site-unic.png'}')`"></div>
          </div>
        </div>
        <div class="info">
          <div class="nome">
            <h3>{{ area?.nome }}</h3>
            <p>{{ area?.descricao.length > 60 ? area.descricao.substring(0, 60) + '...' : area.descricao }}</p>
          </div>
        </div>
        <div class="links">
          <a class="dominio" target="_blank" :href="`https://${area.dominioPersonalizado}`" :class="{ sem: !area.dominioPersonalizado }">
            {{ area.dominioPersonalizado || 'Sem domínio' }}
          </a>
          <a class="hotlink" target="_blank" :href="`https://mber.live/${area.hotlink}`">mber.live/{{ area.hotlink }}</a>
        </div>
        <div class="botoes">
          <div class="status on">
            <span></span>
            <h3>Online</h3>
          </div>
          <button class="icone excluir" @click="abrirModalEdicao('excluirArea', 'excluir', 'Excluir área', area?._id)">
            <Svgs nome="excluir" />
          </button>
          <button class="icone duplicar" @click="abrirModalEdicao('duplicarArea', 'duplicar', 'Duplicar área', area?._id)">
            <Svgs nome="duplicar" />
          </button>
          <button class="editar" @click="editarSite(area?.hotlink)">
            <Svgs nome="editar" />
            <p>Editar área</p>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStoreAreas, useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeModal = useStoreModal()

const searchQuery = ref('')
const selectedFilter = ref('todos')

const areasFiltradas = computed(() => {
  let areas = storeAreas.areas || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    areas = areas.filter((area) => area.nome.toLowerCase().includes(query) || area.descricao.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      areas = areas.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      areas = areas.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      areas = areas.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      areas = areas.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
  }

  return areas
})

function abrirModalEdicao(nome, icone, titulo, id) {
  storeModal.abrirModalEdicao(nome, icone, titulo, id)
}

function editarSite(hotlink) {
  window.location.href = `/areas/${hotlink}`
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 5px 0 20px;
  min-height: 75dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px 20px 0;
}

.busca .input {
  width: calc(80% - 10px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 10px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33.33% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 20px 0;
}

.item .capa {
  width: 100%;
}

.item .capa .tamanho {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20%;
  overflow: hidden;
}

.item .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-position: center;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 0;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .status {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 10px 15px;
  border-radius: 50px;
  margin-right: auto;
}

.item .status span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.item .status h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .status.on span {
  background-color: var(--cor-laranja);
}

.item .status.off span {
  background-color: var(--cor-vermelho);
}

.item .links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links a.dominio {
  border-right: 2px solid var(--cor-cinza-3);
}

.item .links a {
  text-align: center;
  width: 50%;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  padding: 15px 5px;
  transition: all 0.3s;
}

.item .links a.sem {
  opacity: 0.5;
  pointer-events: none;
}

.item .links a:hover {
  color: var(--cor-laranja);
}

.item .botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .botoes button.icone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 35px;
  height: 35px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.item .botoes button.icone svg {
  width: 17px;
  min-width: 17px;
  max-height: 17px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.item .botoes button.icone.excluir:hover {
  background-color: var(--cor-vermelho);
}

.item .botoes button.icone.excluir:hover svg {
  fill: var(--cor-branco-fixo);
}

.item .botoes button.icone.duplicar:hover {
  background-color: var(--cor-laranja);
}

.item .botoes button.icone.excluir:hover svg {
  fill: var(--cor-branco-fixo);
}

.item .botoes button.editar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-laranja);
  padding: 8px 12px;
  border-radius: 10px;
  transition: all 0.3s;
}

.item .botoes button.editar:hover {
  background-color: var(--cor-laranja-escuro);
}

.item .botoes button.editar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.item .botoes button.editar svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco-fixo);
  margin: 0 8px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.lista {
    padding: 20px 20px 0 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(50% - 5px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 5px);
  }

  .busca .reorganizar {
    padding: 12px 0;
    width: calc(15% - 5px);
  }

  .busca .reorganizar svg {
    width: 20px;
    min-width: 20px;
    margin: 0;
  }

  .busca .reorganizar p {
    display: none;
  }

  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .item .info {
    width: 100%;
    padding: 20px;
  }

  .item .foto {
    width: 40px;
    height: 40px;
  }

  .item .nome {
    padding: 0 20px 0 0;
  }

  .item .nome h3 {
    font-size: var(--f1);
  }

  .item .nome p {
    font-size: var(--f0);
  }

  .item .status {
    padding: 8px 12px;
  }

  .item .status h3 {
    font-size: var(--f0);
  }

  .item .links {
    flex-direction: column;
  }

  .item .links a.dominio {
    border-bottom: 2px solid var(--cor-cinza-3);
    border-right: none;
  }

  .item .links a {
    width: 100%;
    font-size: var(--f0);
    padding: 13px 5px;
  }

  .item .botoes {
    padding: 20px;
  }

  .item .botoes button.editar p {
    font-size: var(--f1);
  }

  .item .botoes button.editar svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }
}
</style>
