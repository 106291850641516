<template>
  <button class="salvar">
    {{ nome }}
    <span></span>
  </button>
</template>

<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  nome: {
    type: String,
    required: true
  }
})

const { nome } = toRefs(props)
</script>

<style scoped>
button.salvar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-laranja);
  width: 100%;
  padding: 20px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

button.salvar:hover {
  background-color: var(--cor-laranja-escuro);
}

button.salvar:disabled {
  color: transparent;
}

button.salvar:disabled span {
  opacity: 1;
  visibility: visible;
}

button.salvar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  border-top: 2px solid transparent;
  box-shadow: 0 5px 30px 0 #ffffff5a;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}
</style>
