<template>
  <Modal nome="alterarSenha">
    <label class="um">
      Senha atual
      <span>*</span>
    </label>
    <input type="password" spellcheck="false" placeholder="senha#123" autocomplete="current-password" v-model="state.senhaAtual" />
    <label>
      Nova senha
      <span>*</span>
    </label>
    <input type="password" spellcheck="false" placeholder="senha#123" autocomplete="new-password" v-model="state.senhaNova" />
    <BotaoSalvar nome="Salvar nova senha" @click="salvar" />
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const state = reactive({
  senhaAtual: '',
  senhaNova: ''
})

function validarDados() {
  let mensagem
  if (state.senhaAtual === state.senhaNova) {
    mensagem = 'A nova senha não pode ser igual à senha atual'
  } else if (state.senhaNova?.length > 0 && state.senhaNova?.length < 6) {
    mensagem = 'A senha precisa ter no mínimo 6 caracteres'
  } else {
    return true
  }

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
}

async function salvar() {
  if (!validarDados()) return

  const payloadSenha = {
    senhaAtual: state.senhaAtual,
    senhaNova: state.senhaNova
  }

  if (state.senhaAtual && state.senhaNova) await storePerfil.alterarSenha(payloadSenha)

  await storePerfil.receberPerfil()
  storeModal.fecharModal('alterarSenha')
}

function limparCampos() {
  state.senhaAtual = ''
  state.senhaNova = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label.um {
  margin: 0 0 10px 0;
}
button {
  margin: 20px 0 0 0;
}
/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
