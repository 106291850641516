import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberTodos(idArea = null) {
    const method = 'GET'

    let url = `notificacoes/${idProdutor}`
    if (idArea) {
      url += `?idArea=${idArea}`
    }

    return api(method, url, null, token)
  },

  receberPorId(idNotificacao) {
    const method = 'GET'
    const url = `notificacoes/${idProdutor}/${idNotificacao}`

    return api(method, url, null, token)
  },

  contarNovidades() {
    const method = 'GET'
    const url = `notificacoes/contador/novidades/${idProdutor}`

    return api(method, url, null, token)
  },

  alterarVisto(idNotificacao, payload) {
    const method = 'PATCH'
    const url = `notificacoes/visto/alterar/${idProdutor}/${idNotificacao}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
