<template>
  <div class="particles">
    <canvas ref="canvasRef"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { createNoise3D } from 'simplex-noise'

// Configuração de partículas e constantes visuais
const canvasRef = ref(null)
const noise3D = createNoise3D()
const particleCount = 200
const particles = []
const TAU = 5 * Math.PI
const xOff = 0.002
const yOff = 0.002
const zOff = 0.002
let tick = 0

// Paleta de cores específica
const particleColors = ['#FFBA11', '#FF9D17', '#FF3B1F', '#FA3769', '#DA3DC7']

function initParticles(canvas) {
  for (let i = 0; i < particleCount; i++) {
    particles.push({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      speed: Math.random() * 1.5 + 0.5,
      direction: Math.random() * Math.PI * 2,
      radius: Math.random() * 2 + 1, // Variação no tamanho das partículas
      color: particleColors[Math.floor(Math.random() * particleColors.length)]
    })
  }
}

function draw(ctx, canvas) {
  tick++
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  ctx.fillStyle = 'rgba(0, 0, 0, 0)'
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  particles.forEach((p) => {
    const noise = noise3D(p.x * xOff, p.y * yOff, tick * zOff) * TAU
    p.x += Math.cos(p.direction + noise) * p.speed
    p.y += Math.sin(p.direction + noise) * p.speed

    if (p.x < 0 || p.x > canvas.width || p.y < 0 || p.y > canvas.height) {
      p.x = Math.random() * canvas.width
      p.y = Math.random() * canvas.height
    }

    ctx.save()
    ctx.globalCompositeOperation = 'lighter' // Glow
    ctx.fillStyle = p.color
    ctx.beginPath()
    ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2)
    ctx.fill()
    ctx.restore()
  })

  requestAnimationFrame(() => draw(ctx, canvas))
}

function resizeCanvas(canvas) {
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
}

onMounted(() => {
  const canvas = canvasRef.value
  if (!canvas) return
  const ctx = canvas.getContext('2d')
  resizeCanvas(canvas)
  initParticles(canvas)
  draw(ctx, canvas)

  window.addEventListener('resize', () => resizeCanvas(canvas))
})

onUnmounted(() => {
  window.removeEventListener('resize', () => resizeCanvas(canvasRef.value))
})
</script>

<style>
canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 2;
}
</style>
