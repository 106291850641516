<template>
  <Modal nome="editarIntegracao">
    <label class="um">
      Status da Integração
      <span>*</span>
    </label>
    <div class="select">
      <Svgs nome="setinha" />
      <select v-model="state.ativo">
        <option value="" disabled selected>Selecionar</option>
        <option :value="true">Ativada</option>
        <option :value="false">Desativada</option>
      </select>
    </div>

    <label>
      Nome da integração
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" placeholder="Ex: High Ticket" @keydown.enter="salvarIntegracao" />

    <label>
      Pontuação ganhos na área ao comprar
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="dinheiro" />
      <input v-model="state.pontuacao" type="tel" spellCheck="false" placeholder="Ex: 100" @keydown.enter="salvarIntegracao" />
    </div>

    <label>
      Cursos que a integração vai liberar
      <span>*</span>
    </label>
    <Checklist :dados="state.cursos" />

    <SalvarModal nome="Salvar Alterações" @click="salvarIntegracao()" />
  </Modal>
</template>

<script setup>
import { inject, reactive, computed, onMounted } from 'vue'
import { useStoreModal, useStoreAreas, useStoreIntegracoes, useStoreCursos } from '@stores'

import Checklist from '@components/global/elementos/Checklist.vue'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeIntegracoes = useStoreIntegracoes()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('editarIntegracao'))

const state = reactive({
  cursos: [],
  nome: '',
  gateway: '',
  pontuacao: '',
  ativo: true
})

function limparCampos() {
  state.nome = ''
  state.gateway = ''
  state.pontuacao = ''
  state.cursos = []
  state.ativo = true
}

async function preencherDados() {
  const idArea = storeAreas.area?._id
  if (dadosModal.value.id) {
    await storeIntegracoes.receberPorId(idArea, dadosModal.value.id)
  }

  state.nome = storeIntegracoes.integracao?.nome
  state.pontuacao = storeIntegracoes.integracao?.numeroPontosAoComprar
  state.gateway = storeIntegracoes.integracao?.gateway
  state.ativo = storeIntegracoes.integracao?.ativo

  carregarTodosOsCursos()
}

async function carregarTodosOsCursos() {
  const idArea = storeAreas.area?._id

  if (idArea) {
    await storeCursos.receberTodos(idArea)

    state.cursos = storeCursos.cursos.map((curso) => ({
      nome: curso.nome,
      id: curso._id,
      selecionado: false
    }))

    const idsCursosSelecionados = storeIntegracoes.integracao?.idsCursos || []

    state.cursos.forEach((curso) => {
      curso.selecionado = idsCursosSelecionados.includes(curso.id)
    })
  }
}

async function salvarIntegracao() {
  if (!validarDados()) return

  const idArea = storeAreas.area?._id
  const idIntegracao = storeIntegracoes.integracao?._id

  const payload = {
    idsCursos: state.cursos.filter((curso) => curso.selecionado).map((curso) => curso.id),
    nome: state.nome,
    gateway: state.gateway,
    numeroPontosAoComprar: state.pontuacao,
    ativo: state.ativo
  }

  const adicionadoOk = await storeIntegracoes.alterarDados(idArea, idIntegracao, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarIntegracoes')
  storeModal.fecharModal('editarIntegracao')
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.gateway || !state.pontuacao || !state.cursos) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', preencherDados)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvar {
  margin: 20px 0 0 0;
}
</style>
