<template>
  <button
    :class="{ ativo: modalAberto === 'inicioEditarLayout' }"
    @click="
      abrirModal(
        'inicioEditarLayout', // nome do modal
        'degrade-azul', // cor do icone
        'casa', // nome do icone
        'Tela de Início', // titulo
        'Editar Layout' // subtitulo
      )
    ">
    <p>Editar Layout</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'inicioEditarCores' }"
    @click="
      abrirModal(
        'inicioEditarCores', // nome do modal
        'degrade-azul', // cor do icone
        'casa', // nome do icone
        'Tela de Início', // titulo
        'Editar Cores' // subtitulo
      )
    ">
    <p>Editar Cores</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'inicioEditarTextos' }"
    @click="
      abrirModal(
        'inicioEditarTextos', // nome do modal
        'degrade-azul', // cor do icone
        'casa', // nome do icone
        'Tela de Início', // titulo
        'Editar Textos' // subtitulo
      )
    ">
    <p>Editar Textos</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'inicioEditarFontes' }"
    @click="
      abrirModal(
        'inicioEditarFontes', // nome do modal
        'degrade-azul', // cor do icone
        'casa', // nome do icone
        'Tela de Início', // titulo
        'Editar Fontes' // subtitulo
      )
    ">
    <p>Editar Fontes</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'inicioEditarLinks' }"
    @click="
      abrirModal(
        'inicioEditarLinks', // nome do modal
        'degrade-azul', // cor do icone
        'casa', // nome do icone
        'Tela de Início', // titulo
        'Editar Links' // subtitulo
      )
    ">
    <p>Editar Links</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'inicioEditarImagens' }"
    @click="
      abrirModal(
        'inicioEditarImagens', // nome do modal
        'degrade-azul', // cor do icone
        'casa', // nome do icone
        'Tela de Início', // titulo
        'Editar Imagens' // subtitulo
      )
    ">
    <p>Editar Imagens</p>
  </button>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useStoreModal } from '@stores'

const storeModal = useStoreModal()
const modalAberto = ref('')
const emitter = inject('emitter')

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  if (modalAberto.value && modalAberto.value !== nome) {
    storeModal.fecharModal(modalAberto.value)
  }
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
  modalAberto.value = nome
}

function fecharModal() {
  modalAberto.value = ''
}

onMounted(() => {
  emitter.on('limparCampos', fecharModal)
})
</script>
