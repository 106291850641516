<template>
  <footer>
    <div class="conteudo">
      <p>2024 © Level Member LTDA | CNPJ 55.752.924/0001-36</p>
      <div class="botoes">
        <a href="https://termos.levelmember.com" target="_blank">Termos</a>
        <a href="https://politica.levelmember.com" target="_blank">Política</a>
        <a href="https://cookies.levelmember.com" target="_blank">Cookies</a>
      </div>
    </div>
  </footer>
</template>

<script setup></script>

<style scoped>
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 20px 20px;
  background-color: var(--cor-cinza-1);
  position: relative;
  z-index: 1;
}

footer.default {
  padding: 20px 80px 20px 80px;
}

footer .conteudo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid var(--cor-cinza-3);
  width: 100%;
  padding: 20px 0 0 0;
}

footer p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

footer .botoes {
  margin-left: auto;
}

footer .botoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: transparent;
  margin: 0 0 0 20px;
  transition: all 0.3s;
}

footer .botoes a:hover {
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  footer {
    flex-direction: column;
    padding: 30px 20px 60px 20px;
  }

  footer.default {
    padding: 20px 20px 50px 20px;
  }

  footer .conteudo {
    flex-direction: column;
    padding: 60px 0 0 0;
  }

  footer p {
    line-height: 1.5;
    text-align: center;
    max-width: 250px;
  }

  footer .botoes {
    margin-left: 0;
    padding: 10px 0;
  }

  footer .botoes a {
    margin: 0 10px;
  }

  footer .frase h3 {
    font-size: var(--f1);
  }
}
</style>
