<template>
  <section class="dominio">
    <div class="quadroPrincipal">
      <!-- Primeira etapa -->
      <div class="etapa primeira" v-if="state.etapaUm">
        <label>
          Digite seu domínio
          <span>*</span>
        </label>
        <div class="campo">
          <input type="url" v-model="state.dominioDefinido" autocomplete="false" placeholder="levelmember.com" spellcheck="false" />
          <SalvarModal @click="avancarDois()" :nome="`Configurar ${state.dominioDefinido}`" />
        </div>
        <div class="itens">
          <div class="quadro">
            <div class="icone">
              <Svgs nome="editar" />
            </div>
            <h3>Configuração Fácil</h3>
            <p>Configure seu domínio apenas adicionando um registro A</p>
          </div>
          <div class="quadro">
            <div class="icone">
              <Svgs nome="cursor" />
            </div>
            <h3>Seu domínio é seu</h3>
            <p>Não gerenciamos domínio. Só criamos um registro no seu DNS</p>
          </div>
          <div class="quadro">
            <div class="icone">
              <Svgs nome="servidor" />
            </div>
            <h3>Hospedagem</h3>
            <p>Sua área já está hospedada e ativa na sua conta Level Member</p>
          </div>
          <div class="quadro">
            <div class="icone">
              <Svgs nome="cadeado" />
            </div>
            <h3>Certificado SSL</h3>
            <p>Seu certificado SSL já está ativo e funcionando em sua área</p>
          </div>
        </div>
      </div>

      <!-- Segunda etapa -->
      <div class="etapa segunda" v-if="state.etapaDois">
        <div class="atual">
          <h3>Configure o domínio {{ state.dominioDefinido }}</h3>
          <button @click="voltarEtapa()">
            <Svgs nome="editar" />
            <p>Alterar</p>
          </button>
        </div>
        <div class="tutorial">
          <div class="quadro">
            <Svgs class="icone" nome="cursor" />
            <h2>Vá até o local onde foi comprado seu domínio e crie um registro DNS com os dados do quadro ao lado</h2>
            <a target="_blank" href="https://arquivos.levelmember.com/imagens/tutoriais/dominio-level.pdf">
              <Svgs nome="duvida" />
              Como configurar
            </a>
          </div>
          <div class="quadro">
            <div class="linha">
              <p>Tipo</p>
              <h3>A</h3>
            </div>
            <div class="linha">
              <p>Nome</p>
              <h3>{{ state.subdominio || '@' }}</h3>
            </div>
            <div class="linha">
              <p>Destino</p>
              <h3 @click="copiarIp()">104.248.58.128</h3>
            </div>
            <div class="linha">
              <p>TTL</p>
              <h3>O menor possível</h3>
            </div>
          </div>
        </div>

        <div class="confirmar">
          <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
            <span>
              <Svgs nome="check" />
            </span>
            <p>Pronto, fiz o que foi solicitado acima</p>
          </div>

          <SalvarModal nome="Salvar meu domínio" :disabled="state.loading" @click="verificarDNS()" />
        </div>
      </div>

      <!-- Domínio configurado -->
      <div class="etapa configurado" v-if="state.etapaCadastrado">
        <div class="atual">
          <div class="nome">
            <Svgs nome="dominio" />
            <p>Seu domínio configurado é</p>
            <h3>{{ state.dominioDefinido }}</h3>
          </div>
          <button class="excluir" @click="abrirModal('excluirDominio', 'excluir', 'Excluir Domínio')">
            <Svgs nome="excluir" />
            Excluir Domínio
          </button>
        </div>
        <div class="tutorial">
          <div class="quadro">
            <Svgs class="icone" nome="cursor" />
            <h2>Vá até o local onde foi comprado seu domínio e crie um registro DNS com os dados do quadro ao lado</h2>
            <a target="_blank" href="https://arquivos.levelmember.com/imagens/tutoriais/dominio-level.pdf">
              <Svgs nome="duvida" />
              Como configurar
            </a>
          </div>
          <div class="quadro">
            <div class="linha">
              <p>Tipo</p>
              <h3>A</h3>
            </div>
            <div class="linha">
              <p>Nome</p>
              <h3>{{ state.subdominio || '@' }}</h3>
            </div>
            <div class="linha">
              <p>Destino</p>
              <h3 @click="copiarIp()">104.248.58.128</h3>
            </div>
            <div class="linha">
              <p>TTL</p>
              <h3>O menor possível</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, inject, watch } from 'vue'
import { useStoreDominios, useStoreAreas, useStoreModal } from '@stores'
import Svgs from '@svgs'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'

const emitter = inject('emitter')
const storeDominios = useStoreDominios()
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()

const state = reactive({
  etapaUm: true,
  etapaDois: false,
  etapaTres: false,
  etapaCadastrado: false,
  loading: false,
  confirmar: false,
  dominioDefinido: '',
  subdominio: ''
})

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}

function avancarDois() {
  if (!state.dominioDefinido) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Preencha o domínio que deseja' })
  } else {
    state.etapaUm = false
    state.etapaDois = true
    extrairSubdominio()
  }
}

function voltarEtapa() {
  state.etapaUm = true
  state.etapaDois = false
}

function extrairSubdominio() {
  const dominio = state.dominioDefinido
  const partes = dominio.split('.')

  if (partes.length > 2) {
    state.subdominio = partes[0]
  } else {
    state.subdominio = ''
  }
}

async function copiarIp() {
  const url = `104.248.58.128`
  await navigator.clipboard.writeText(url)
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Destino copiado!' })
}

async function verificarDNS() {
  if (!state.confirmar) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Confirme que fez as configurações' })
    return
  }
  state.loading = true

  const dominioOK = await storeDominios.validarDominioDNS(state.dominioDefinido)
  state.loading = false
  if (!dominioOK) return

  state.loading = false
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Domínio configurado!' })

  const payload = {
    dominioPersonalizado: state.dominioDefinido,
    idArea: storeAreas.area?._id
  }
  const dominioAlteradoOk = await storeAreas.alterarDominio(payload)
  if (!dominioAlteradoOk) return

  limparCampos()
  window.location.reload()
}

function limparCampos() {
  state.dominioDefinido = ''
  state.subdominio = ''
  state.etapaUm = true
  state.etapaDois = false
  state.etapaTres = false
  state.etapaCadastrado = false
  state.loading = false
  state.confirmar = false
}

function verificarDominio() {
  if (!storeAreas.area?.dominioPersonalizado) {
    state.etapaUm = true
  } else {
    state.dominioDefinido = storeAreas.area?.dominioPersonalizado
    extrairSubdominio()
    state.etapaUm = false
    state.etapaCadastrado = true
  }
}

watch(
  () => storeAreas.area,
  (novoArea) => {
    if (novoArea && novoArea.dominioPersonalizado !== undefined) {
      verificarDominio()
    }
  },
  { immediate: true }
)
</script>

<style scoped>
.dominio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 100dvh;
}

.quadroPrincipal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.etapa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  animation: fadeIn 0.3s linear;
}

.primeira .itens {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.primeira .itens .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-cinza-2);
  width: calc(25% - 12px);
  border-radius: 10px;
  padding: 70px 20px;
}

.primeira .itens .quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--cor-laranja);
}

.primeira .itens .quadro .icone svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-laranja);
}

.primeira .itens .quadro h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
}

.primeira .itens .quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  max-width: 250px;
}

.primeira label {
  margin: 0 0 10px 0;
}

.primeira .campo {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 20px 0;
}

.primeira .campo input {
  padding: 20px;
}

.primeira .campo button {
  max-width: calc(25% - 12px);
  margin: 0 0 0 15px;
}

.segunda .atual {
  display: flex;
  width: 100%;
}

.segunda .atual h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  background-color: transparent;
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
}

.segunda .atual button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.segunda .atual button:hover {
  color: var(--cor-cinza-5);
}

.segunda .atual button:hover svg {
  fill: var(--cor-cinza-5);
}

.segunda .atual button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.segunda .tutorial {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px 0;
}

.segunda .tutorial .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 8px);
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  padding: 60px 40px;
}

.segunda .tutorial .quadro svg.icone {
  width: 40px;
  min-width: 40px;
  fill: var(--cor-branco);
}

.segunda .tutorial .quadro h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 20px 0 0 0;
  text-align: center;
  width: 100%;
  max-width: 340px;
}

.segunda .tutorial .quadro a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-laranja);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.segunda .tutorial .quadro a:hover {
  opacity: 0.6;
}

.segunda .tutorial .quadro a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.segunda .tutorial .quadro .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.segunda .tutorial .quadro .linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.segunda .tutorial .quadro .linha h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.segunda .confirmar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
}

.segunda .confirmar button {
  width: auto;
}

.inputAtivacao {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

.inputAtivacao:hover p {
  color: var(--cor-branco);
}

.inputAtivacao span svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-3);
  transition: all 0.3s;
}

.inputAtivacao.ativo span {
  background-color: var(--cor-verde);
}

.inputAtivacao.ativo span svg {
  fill: var(--cor-branco-fixo);
}

.inputAtivacao.ativo p {
  color: var(--cor-verde);
}

.inputAtivacao span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  border-radius: 5px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.inputAtivacao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.configurado .atual {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: transparent;
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
}

.configurado .atual .nome {
  display: flex;
  align-items: center;
}

.configurado .atual .nome svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
}

.configurado .atual .nome p {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-cinza-5);
  margin: 0 5px;
}

.configurado .atual .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-laranja);
}

.configurado .atual button.excluir {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-vermelho);
  padding: 13px 25px;
  border-radius: 10px;
  transition: all 0.3s;
}

.configurado .atual button.excluir:hover {
  background-color: var(--cor-vermelho-escuro);
}

.configurado .atual button.excluir svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.configurado .tutorial {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px 0;
}

.configurado .tutorial .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 8px);
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  padding: 60px 40px;
}

.configurado .tutorial .quadro svg.icone {
  width: 40px;
  min-width: 40px;
  fill: var(--cor-branco);
}

.configurado .tutorial .quadro h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 20px 0 0 0;
  text-align: center;
  width: 100%;
  max-width: 340px;
}

.configurado .tutorial .quadro a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-laranja);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.configurado .tutorial .quadro a:hover {
  opacity: 0.6;
}

.configurado .tutorial .quadro a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.configurado .tutorial .quadro .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.configurado .tutorial .quadro .linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.configurado .tutorial .quadro .linha h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.dominio {
    padding: 0 20px;
  }

  .primeira .itens {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .primeira .itens .quadro {
    width: 100%;
    padding: 40px 20px;
    margin: 0 0 20px 0;
  }

  .primeira .comecar {
    flex-direction: column;
    padding: 40px 30px;
  }

  .primeira .comecar p {
    margin: 0 0 20px 0;
  }

  .primeira .campo {
    flex-direction: column;
  }

  .primeira .campo button {
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  .segunda .tutorial {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .segunda .tutorial .quadro {
    width: 100%;
    padding: 40px 30px;
    margin: 0 0 20px 0;
  }

  .segunda .tutorial .quadro .linha {
    padding: 10px 0;
  }

  .segunda .confirmar {
    flex-direction: column;
    align-items: flex-start;
  }

  .segunda .confirmar button {
    margin: 20px 0 0 0;
  }

  .configurado .atual {
    flex-direction: column;
    align-items: flex-start;
  }

  .configurado .atual .nome {
    flex-wrap: wrap;
  }

  .configurado .atual .nome h3 {
    width: 100%;
    margin: 15px 0 20px 0;
  }

  .configurado .tutorial {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .configurado .tutorial .quadro {
    width: 100%;
    padding: 40px 30px;
    margin: 0 0 20px 0;
  }

  .configurado .tutorial .quadro .linha {
    padding: 10px 0;
  }
}
</style>
