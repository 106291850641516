<template>
  <section class="modulos" :class="tela.layout" :style="conteudoEstilo">
    <div class="menu">
      <h3>Menu</h3>
      <p>
        Personalize na aba
        <Svgs nome="menu" />
        Menu da Área
      </p>
    </div>
    <!-- Apresentação -->
    <div class="apresentacao">
      <div class="sombra" :style="estiloSombra"></div>
      <div class="fundo desktop" :style="capaEstilo"></div>
      <div class="fundo mobile" :style="capaMobileEstilo"></div>
      <div class="texto">
        <div class="nome">
          <h2 :style="tituloEstilo">Nome do curso</h2>
          <p :style="textoItemEstilo">Descrição do curso</p>
        </div>
        <div class="progresso" :style="infoEstilo">
          <div class="faixa" :style="progressoFaixaEstilo">
            <div class="atual" :style="progressoAtualEstilo"></div>
          </div>
          <h3 :style="textoItemEstilo">
            1 de 5 módulos concluídos
            <span>20%</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- Lista -->
    <div class="lista">
      <div class="elemento" v-for="modulo in state.modulos" :key="modulo">
        <div class="modulo">
          <div class="capa">
            <div class="tamanho">
              <div class="imagem" style="background-image: url('https://arquivos.levelmember.com/imagens/default/background-vertical.svg')"></div>
            </div>
          </div>
          <div class="info" :style="infoEstilo">
            <h5 :style="posicaoEstilo">{{ modulo.posicao }}º</h5>
            <div class="nome">
              <h3 :style="tituloEstilo">{{ modulo.nome }}</h3>
              <p :style="tituloEstilo">{{ modulo.descricao }}</p>
            </div>
            <div class="item">
              <Svgs :style="svgItemEstilo" nome="play" />
              <p :style="textoItemEstilo">{{ modulo.quantidadeAulas }}</p>
            </div>
            <div class="item">
              <Svgs :style="svgItemEstilo" nome="relogio" />
              <p :style="textoItemEstilo">{{ modulo.totalDuracao }} de conteúdo</p>
            </div>
            <div class="botoes">
              <button :style="botaoEstilo">
                {{ tela.textoBotao || 'Começar' }}
                <Svgs :style="svgBotaoEstilo" nome="seta" />
              </button>
            </div>
          </div>
        </div>
        <div class="caminho um">
          <span class="um" :style="caminhoEstilo"></span>
          <span class="dois" :style="caminhoEstilo"></span>
          <span class="tres" :style="caminhoEstilo"></span>
        </div>
        <div class="caminho dois">
          <span class="um" :style="caminhoEstilo"></span>
          <span class="dois" :style="caminhoEstilo"></span>
        </div>
        <div class="caminho tres">
          <span class="um" :style="caminhoEstilo"></span>
          <span class="dois" :style="caminhoEstilo"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { useStorePersonalizacao } from '@stores'
import Svgs from '@svgs'

const storePersonalizacao = useStorePersonalizacao()

const state = reactive({
  modulos: [
    {
      posicao: '1',
      nome: 'Módulo 1',
      descricao: 'Esse é um módulo!',
      quantidadeAulas: '2 aulas',
      totalDuracao: '3h'
    },
    {
      posicao: '2',
      nome: 'Módulo 2',
      descricao: 'Esse é outro módulo!',
      quantidadeAulas: '4 aulas',
      totalDuracao: '5h'
    },
    {
      posicao: '3',
      nome: 'Módulo 3',
      descricao: 'Esse é outro módulo!',
      quantidadeAulas: '4 aulas',
      totalDuracao: '5h'
    },
    {
      posicao: '4',
      nome: 'Módulo 4',
      descricao: 'Esse é outro módulo!',
      quantidadeAulas: '4 aulas',
      totalDuracao: '5h'
    },
    {
      posicao: '5',
      nome: 'Módulo 5',
      descricao: 'Esse é outro módulo!',
      quantidadeAulas: '4 aulas',
      totalDuracao: '5h'
    },
    {
      posicao: '6',
      nome: 'Módulo 6',
      descricao: 'Esse é outro módulo!',
      quantidadeAulas: '4 aulas',
      totalDuracao: '5h'
    },
    {
      posicao: '7',
      nome: 'Módulo 7',
      descricao: 'Esse é outro módulo!',
      quantidadeAulas: '4 aulas',
      totalDuracao: '5h'
    }
  ]
})

const tela = computed(() => storePersonalizacao.personalizacao?.telaCurso || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const capaEstilo = computed(() => ({
  backgroundImage: `url('${tela.value.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`
}))

const capaMobileEstilo = computed(() => ({
  backgroundImage: `url('${tela.value.imagemCapaMobile || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`
}))

const estiloSombra = computed(() => ({
  backgroundImage: `linear-gradient(to top, ${tela.value.corFundo || '#E1E3EB'}, transparent)`
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao || '#262836',
  color: tela.value.corTextoBotao || '#FFFFFF'
}))

const infoEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro || '#FFFFFF'
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulos || '#262836'
}))

const svgItemEstilo = computed(() => ({
  fill: tela.value.corTags || '#262836'
}))

const textoItemEstilo = computed(() => ({
  color: tela.value.corTags || '#262836'
}))

const posicaoEstilo = computed(() => ({
  color: tela.value.corPosicaoTexto || '#E1E3EB',
  backgroundColor: tela.value.corPosicaoFundo || '#262836'
}))

const caminhoEstilo = computed(() => ({
  borderColor: tela.value.corCaminho || '#64677c'
}))

function atualizarFonte() {
  const fontFamily = tela.value.fonte || 'Inter'
  document.documentElement.style.setProperty('--fonte-tela', fontFamily)

  const url = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}&display=swap`

  const linkExistente = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!linkExistente) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watch(
  () => storePersonalizacao.personalizacao?.telaCurso,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.modulos {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 0 300px;
  background-color: #e1e3eb;
  background-size: cover;
  background-position: center;
  position: relative;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-gelo);
  width: 300px;
  height: calc(100dvh - 90px);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.menu h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  margin: 0 0 10px 0;
}

.menu p {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

.menu p svg {
  width: 11px;
  min-width: 11px;
  fill: var(--cor-cinza);
  margin: 0 5px;
}

.apresentacao {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100px;
  width: 100%;
}

.apresentacao .sombra {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.apresentacao .fundo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
}

.apresentacao .fundo.desktop {
  display: flex;
}

.apresentacao .fundo.mobile {
  display: none;
}

.apresentacao .texto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 0 30px;
  z-index: 3;
}

.apresentacao .texto .nome h2 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #ffffff;
  line-height: 1.2;
}

.apresentacao .texto .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.apresentacao .texto .progresso {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  padding: 20px;
}

.apresentacao .texto .progresso h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  width: 100%;
  margin: 10px 0 0 0;
}

.apresentacao .texto .progresso h3 span {
  font-size: var(--f1);
}

.apresentacao .texto .progresso .faixa {
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #ffffffa0;
}

.apresentacao .texto .progresso .faixa .atual {
  width: 40%;
  height: 5px;
  border-radius: 20px;
  background-color: #fff;
}

.lista {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  padding: 30px;
}

section.modulos.tres .lista {
  flex-direction: column;
  flex-wrap: nowrap;
}

.vazio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vazio h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
}

.elemento {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

section.modulos.tres .elemento {
  width: 100%;
}

section.modulos.tres .elemento:nth-child(3n + 1) {
  padding: 0;
  width: 100%;
}

section.modulos.dois .elemento:nth-child(3n + 1) {
  padding: 50px 0 0 0;
}

.elemento:nth-child(3n + 1) {
  justify-content: flex-start;
  width: 50%;
  padding: 100px 100px 0 0;
}

section.modulos.tres .elemento:nth-child(3n + 2) {
  padding: 0;
  width: 100%;
  justify-content: flex-start;
}

section.modulos.dois .elemento:nth-child(3n + 2) {
  padding: 50px 0 0 0;
}

.elemento:nth-child(3n + 2) {
  justify-content: flex-end;
  width: 50%;
  padding: 100px 100px 0 0;
}

section.modulos.tres .elemento:nth-child(3n) {
  padding: 0;
  justify-content: flex-start;
}

section.modulos.dois .elemento:nth-child(3n) {
  padding: 50px 0 50px 0;
}

.elemento:nth-child(3n) {
  justify-content: center;
  width: 100%;
  padding: 200px 100px 100px 0;
}

.caminho {
  position: absolute;
  z-index: 1;
}

.elemento:nth-last-child(1) .caminho {
  display: none !important;
}

section.modulos.tres .elemento .caminho {
  display: none;
}

/* Caminho elementos A */

.elemento:nth-child(3n + 1) .caminho.dois {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.tres {
  display: none;
}

section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um {
  left: 200px;
  bottom: 220px;
  width: calc(100dvw - 815px);
}

.elemento:nth-child(3n + 1) .caminho.um {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100dvw - 1015px);
  left: 350px;
  bottom: 100px;
}

section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um span.um {
  width: 100%;
  margin: 0;
}

.elemento:nth-child(3n + 1) .caminho.um span.um {
  width: 50%;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed;
  margin: 0 0 100px 0;
}

section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um span.dois {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.um span.dois {
  width: 2px;
  height: 100px;
  background-color: transparent;
  border-left: 3px dashed;
}

section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um span.tres {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.um span.tres {
  width: 50%;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed;
  margin: 100px 0 0 0;
}

/* Caminho elementos B */

.elemento:nth-child(3n + 2) .caminho.um {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.tres {
  display: none;
}

section.modulos.dois .elemento:nth-child(3n + 2) .caminho.dois {
  right: 120px;
  width: calc(50dvw - 300px);
  bottom: -320px;
}

.elemento:nth-child(3n + 2) .caminho.dois {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: calc(50dvw - 480px);
  right: 150px;
  bottom: -200px;
}

section.modulos.dois .elemento:nth-child(3n + 2) .caminho.dois span.um {
  height: 320px;
}

.elemento:nth-child(3n + 2) .caminho.dois span.um {
  width: 2px;
  height: 200px;
  background-color: transparent;
  border-left: 3px dashed;
}

.elemento:nth-child(3n + 2) .caminho.dois span.dois {
  width: 100%;
  height: 2px;
  background-color: transparent;
  border-bottom: 3px dashed;
}

/* Caminho elementos C */

.elemento:nth-child(3n) .caminho.um {
  display: none;
}

.elemento:nth-child(3n) .caminho.tres {
  display: none;
}

section.modulos.dois .elemento:nth-child(3n) .caminho.dois {
  left: 120px;
  width: calc(50dvw - 350px);
  bottom: -90px;
}

.elemento:nth-child(3n) .caminho.dois {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(50dvw - 480px);
  left: 255px;
  bottom: 0;
}

section.modulos.dois .elemento:nth-child(3n) .caminho.dois span.um {
  width: calc(100% - 90px);
}

.elemento:nth-child(3n) .caminho.dois span.um {
  width: calc(100% - 105px);
  height: 2px;
  background-color: transparent;
  border-bottom: 3px dashed;
}

section.modulos.dois .elemento:nth-child(3n) .caminho.dois span.dois {
  height: 300px;
}

.elemento:nth-child(3n) .caminho.dois span.dois {
  width: 2px;
  height: 200px;
  background-color: transparent;
  border-left: 3px dashed;
}

section.modulos.tres .modulo {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 100%;
  margin: 0 0 20px 0;
}

.modulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 250px;
}

section.modulos.tres .modulo .capa {
  position: inherit;
  top: auto;
  right: auto;
  max-width: 200px;
}

section.modulos.dois .modulo .capa {
  position: inherit;
  top: auto;
  right: auto;
  max-width: 250px;
}

.modulo .capa {
  width: 100%;
  max-width: 200px;
  position: absolute;
  top: -100px;
  right: -100px;
  z-index: 2;
}

section.modulos.tres .modulo .capa .tamanho {
  padding-bottom: 56.52%;
}

.modulo .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}

section.modulos.tres .modulo .capa .tamanho .imagem {
  border-radius: 10px 0 0 10px;
}

section.modulos.dois .modulo .capa .tamanho .imagem {
  border-radius: 10px 10px 0 0;
}

.modulo .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

section.modulos.tres .modulo .info {
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-radius: 0 10px 10px 0;
}

section.modulos.dois .modulo .info {
  border-radius: 0 0 10px 10px;
}

.modulo .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  z-index: 3;
  position: relative;
}

section.modulos.tres .modulo .info h5 {
  right: auto;
  left: -20px;
  top: 20px;
}

.modulo .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dee0e6;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  text-align: center;
}

section.modulos.tres .modulo .info .nome {
  padding: 0 0 0 20px;
  width: auto;
  margin-right: auto;
}

.modulo .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.modulo .info .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
  line-height: 1.5;
}

.modulo .info .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
}

section.modulos.tres .modulo .info .item {
  padding: 0 30px 0 0;
  width: auto;
}

.modulo .info .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 20px 0 20px;
}

.modulo .info .item p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #64677c;
}

.modulo .info .item svg {
  width: 15px;
  min-width: 15px;
  fill: #64677c;
  margin: 0 5px 0 0;
}

section.modulos.tres .modulo .info .botoes {
  padding: 0;
  width: auto;
}

.modulo .info .botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px 20px 20px;
}

.modulo .info .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #64677c;
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 50px;
  transition: all 0.3s;
}

.modulo .info .botoes button:hover {
  transform: scale(1.05);
}

.modulo .info .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: #ffffff;
  margin: 0 0 0 5px;
}

@media screen and (max-width: 1000px) {
  section.modulos {
    padding: 0;
  }

  .menu {
    display: none;
  }

  .apresentacao {
    flex-direction: column;
    justify-content: flex-end;
    height: 250px;
  }

  .apresentacao .fundo {
    opacity: 0.3;
  }

  .apresentacao .fundo.desktop {
    display: none;
  }

  .apresentacao .fundo.mobile {
    display: flex;
  }

  .apresentacao .texto {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 20px 20px 20px 20px;
    z-index: 3;
  }

  .apresentacao .texto .progresso {
    width: 100%;
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  .lista {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  section.modulos.um .lista {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 20px 100px 20px;
  }

  section.modulos.tres .lista {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 20px 20px 20px;
  }

  section.modulos.tres .elemento {
    width: 100%;
  }

  section.modulos.tres .elemento:nth-child(3n + 1) {
    padding: 0;
    width: 100%;
  }

  section.modulos.um .elemento:nth-child(3n + 1) {
    padding: 110px 50px 0 0;
  }

  section.modulos.dois .elemento:nth-child(3n + 1) {
    padding: 0 0 50px 0;
  }

  .elemento:nth-child(3n + 1) {
    justify-content: center;
    width: 100%;
    padding: 0 50px 150px 0;
  }

  section.modulos.tres .elemento:nth-child(3n + 2) {
    padding: 0;
    width: 100%;
    justify-content: center;
  }

  section.modulos.um .elemento:nth-child(3n + 2) {
    padding: 150px 50px 0 0;
  }

  section.modulos.dois .elemento:nth-child(3n + 2) {
    padding: 0 0 50px 0;
  }

  .elemento:nth-child(3n + 2) {
    justify-content: center;
    width: 100%;
    padding: 0 50px 150px 0;
  }

  section.modulos.tres .elemento:nth-child(3n) {
    padding: 0;
    justify-content: flex-start;
  }

  section.modulos.um .elemento:nth-child(3n) {
    padding: 150px 50px 0 0;
  }

  section.modulos.dois .elemento:nth-child(3n) {
    padding: 0 0 50px 0;
  }

  .elemento:nth-child(3n) {
    justify-content: center;
    width: 100%;
    padding: 0 50px 150px 0;
  }

  .caminho {
    position: absolute;
    z-index: 1;
  }

  .elemento:nth-last-child(1) .caminho {
    display: none !important;
  }

  section.modulos.tres .elemento .caminho {
    display: none;
  }

  /* Caminho elementos A */

  .elemento:nth-child(3n + 1) .caminho.dois {
    display: none;
  }

  .elemento:nth-child(3n + 1) .caminho.tres {
    display: none;
  }

  section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um {
    left: auto;
    bottom: -100px;
    width: auto;
  }

  .elemento:nth-child(3n + 1) .caminho.um {
    display: flex;
    align-items: center;
    justify-content: center;
    left: auto;
    bottom: -100px;
  }

  section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um span.um {
    width: 100%;
    margin: 0;
  }

  .elemento:nth-child(3n + 1) .caminho.um span.um {
    display: none;
  }

  section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um span.dois {
    display: flex;
  }

  .elemento:nth-child(3n + 1) .caminho.um span.dois {
    width: 2px;
    height: 100px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  section.modulos.dois .elemento:nth-child(3n + 1) .caminho.um span.tres {
    display: none;
  }

  .elemento:nth-child(3n + 1) .caminho.um span.tres {
    display: none;
  }

  /* Caminho elementos B */

  .elemento:nth-child(3n + 2) .caminho.um {
    display: none;
  }

  .elemento:nth-child(3n + 2) .caminho.tres {
    display: none;
  }

  section.modulos.dois .elemento:nth-child(3n + 2) .caminho.dois {
    right: 120px;
    width: calc(50dvw - 300px);
    bottom: -320px;
  }

  .elemento:nth-child(3n + 2) .caminho.dois {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: auto;
    right: auto;
    bottom: -100px;
  }

  section.modulos.dois .elemento:nth-child(3n + 2) .caminho.dois span.um {
    height: 320px;
  }

  .elemento:nth-child(3n + 2) .caminho.dois span.um {
    width: 2px;
    height: 100px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  .elemento:nth-child(3n + 2) .caminho.dois span.dois {
    display: none;
  }

  /* Caminho elementos C */

  .elemento:nth-child(3n) .caminho.um {
    display: none;
  }

  .elemento:nth-child(3n) .caminho.tres {
    display: none;
  }

  section.modulos.dois .elemento:nth-child(3n) .caminho.dois {
    left: 120px;
    width: calc(50dvw - 350px);
    bottom: -90px;
  }

  .elemento:nth-child(3n) .caminho.dois {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    left: auto;
    bottom: -100px;
  }

  section.modulos.dois .elemento:nth-child(3n) .caminho.dois span.um {
    width: calc(100% - 90px);
  }

  .elemento:nth-child(3n) .caminho.dois span.um {
    width: calc(100% - 105px);
    height: 2px;
    background-color: transparent;
    border-bottom: 3px dashed;
  }

  section.modulos.dois .elemento:nth-child(3n) .caminho.dois span.dois {
    height: 100px;
  }

  .elemento:nth-child(3n) .caminho.dois span.dois {
    width: 2px;
    height: 100px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  section.modulos.tres .modulo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  .modulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 250px;
  }

  section.modulos.dois .modulo {
    max-width: 100%;
  }

  section.modulos.tres .modulo .capa {
    position: inherit;
    top: auto;
    right: auto;
    max-width: 100%;
  }

  section.modulos.dois .modulo .capa {
    position: inherit;
    top: auto;
    right: auto;
    max-width: 100%;
  }

  .modulo .capa {
    width: 100%;
    max-width: 200px;
    position: absolute;
    top: -100px;
    right: -50px;
    z-index: 2;
  }

  section.modulos.dois .modulo .capa .tamanho {
    padding-bottom: 56.52%;
  }

  section.modulos.tres .modulo .capa .tamanho {
    padding-bottom: 56.52%;
  }

  .modulo .capa .tamanho {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
  }

  section.modulos.tres .modulo .capa .tamanho .imagem {
    border-radius: 10px 10px 0 0;
  }

  section.modulos.dois .modulo .capa .tamanho .imagem {
    border-radius: 10px 10px 0 0;
  }

  .modulo .capa .tamanho .imagem {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  section.modulos.tres .modulo .info {
    flex-direction: column;
    justify-content: center;
    padding: 30px 20px;
    border-radius: 0 0 10px 10px;
  }

  section.modulos.dois .modulo .info {
    border-radius: 0 0 10px 10px;
  }

  .modulo .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    z-index: 3;
    position: relative;
  }

  section.modulos.tres .modulo .info h5 {
    right: auto;
    left: 20px;
    top: -20px;
  }

  .modulo .info h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #dee0e6;
    font-family: var(--fonte-tela);
    font-size: var(--f2);
    color: #262836;
    text-align: center;
  }

  section.modulos.tres .modulo .info .nome {
    padding: 0;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-right: auto;
  }

  .modulo .info .nome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  .modulo .info .nome h3 {
    font-family: var(--fonte-tela);
    font-size: var(--f3);
    color: #262836;
    line-height: 1.5;
  }

  .modulo .info .nome p {
    font-family: var(--fonte-tela);
    font-size: var(--f2);
    color: #262836;
    line-height: 1.5;
  }

  section.modulos.tres .modulo .info .item {
    padding: 10px 0 0 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .modulo .info .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 20px 0 20px;
  }

  .modulo .info .item p {
    font-family: var(--fonte-tela);
    font-size: var(--f2);
    color: #64677c;
  }

  .modulo .info .item svg {
    width: 15px;
    min-width: 15px;
    fill: #64677c;
    margin: 0 5px 0 0;
  }

  section.modulos.tres .modulo .info .botoes {
    padding: 10px 0 0 0;
    width: auto;
  }
}
</style>
