<template>
  <div class="inputImagem" :class="{ carregar: imageState.loading }" ref="uploaderContainer" @click="abrirUploader">
    <span></span>
    <div class="previa" :style="{ backgroundImage: `url(${imageState.previewUrl})` }">
      <Svgs v-if="!imageState.previewUrl" nome="arquivo" />
    </div>
    <input type="file" accept=".jpg,.jpeg,.png" hidden ref="inputFile" @change="selecionarImagem" />
    <div class="texto">
      <h3>{{ props.uploadText || 'Subir Imagem' }}</h3>
      <p>{{ imageState.fileSize || props.defaultSize }}</p>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import Svgs from '@svgs'
import ApiUpload from '@/api/upload/api-upload.js'

const props = defineProps({
  valor: String, // v-model para a URL da imagem
  defaultSize: {
    type: String,
    default: 'Tamanho recomendado: 800x800' // Mensagem padrão do tamanho
  },
  uploadText: {
    type: String,
    default: 'Subir Imagem' // Texto padrão do botão
  },
  maxWidth: {
    type: Number,
    default: 800 // Largura máxima padrão
  },
  onAtualizarImagem: Function // Callback para enviar a URL da imagem
})

const imageState = reactive({
  previewUrl: props.valor || '',
  loading: false,
  fileName: '',
  fileSize: ''
})

const inputFile = ref(null)

function abrirUploader() {
  inputFile.value.click()
}

function selecionarImagem(event) {
  const file = event.target.files[0]
  if (!file) return

  imageState.loading = true

  const payload = {
    arquivo: file,
    maxWidth: props.maxWidth
  }

  ApiUpload.uploadImagem(payload)
    .then((response) => {
      imageState.previewUrl = response?.url
      imageState.fileName = response?.nome
      imageState.fileSize = response?.peso
      if (typeof props.onAtualizarImagem === 'function') {
        props.onAtualizarImagem(response?.url)
      }
    })
    .catch(() => {
      console.error('Erro ao subir imagem')
    })
    .finally(() => {
      file.value = ''
      imageState.loading = false
    })
}

watch(
  () => props.valor,
  (novaUrl) => {
    imageState.previewUrl = novaUrl
  },
  { immediate: true }
)
</script>
