import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiCursos from '../api/produtor/api-cursos'

export const useStoreCursos = defineStore('storeCursos', {
  state: () => {
    return {
      cursos: null,
      curso: null,
      idCurso: ''
    }
  },
  actions: {
    async receberTodos(idArea) {
      try {
        const resp = await apiCursos.receberTodos(idArea)
        this.cursos = resp
        return resp
      } catch (error) {
        this.cursos = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(idArea, hotlink) {
      try {
        const resp = await apiCursos.receberPorHotlink(idArea, hotlink)
        this.curso = resp
        return true
      } catch (error) {
        this.curso = null
        return false
      }
    },

    async receberPorId(idArea, idCurso) {
      try {
        const resp = await apiCursos.receberPorId(idArea, idCurso)
        this.curso = resp
        return true
      } catch (error) {
        this.curso = null
        return false
      }
    },

    async adicionar(idArea, payload) {
      try {
        const resp = await apiCursos.adicionar(idArea, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async duplicar(idArea, idCurso) {
      try {
        const resp = await apiCursos.duplicar(idArea, idCurso)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(idArea, idCurso, payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiCursos.alterarDados(idArea, idCurso, payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idArea, idCurso) {
      try {
        const resp = await apiCursos.deletar(idArea, idCurso)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async ordenarPosicoes(idArea, payload) {
      try {
        const resp = await apiCursos.ordenarPosicoes(idArea, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
