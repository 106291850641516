<template>
  <div id="alunos">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalCriarAluno />
    <ModalEditarAluno />
    <ModalImportarAlunos />
    <ModalResetarSenha />
    <SectionTopo />
    <SectionLista />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreAreas, useStoreAlunos } from '@stores'

import Loading from '@components/global/loading/LoadingArea.vue'
import ModalCriarAluno from '@components/areaDeMembros/alunos/modals/CriarAluno.vue'
import ModalEditarAluno from '@components/areaDeMembros/alunos/modals/EditarAluno.vue'
import ModalImportarAlunos from '@components/areaDeMembros/alunos/modals/ImportarAlunos.vue'
import ModalResetarSenha from '@components/areaDeMembros/alunos/modals/ResetarSenha.vue'
import SectionTopo from '@components/areaDeMembros/alunos/partials/SectionTopo.vue'
import SectionLista from '@components/areaDeMembros/alunos/partials/SectionLista.vue'

const emitter = inject('emitter')
const storeAlunos = useStoreAlunos()
const storeAreas = useStoreAreas()

const state = reactive({
  verLoading: true
})

async function carregarAlunos() {
  const idArea = storeAreas.area?._id

  if (idArea) {
    storeAlunos.receberTodos(idArea)

    setTimeout(() => {
      state.verLoading = false
    }, 300)
  }
}

watch(
  () => storeAreas.area?._id,
  (idArea) => {
    if (idArea) {
      carregarAlunos()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarAlunos', carregarAlunos)
})
</script>

<style scoped>
#alunos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
