<template>
  <Modal nome="criarAluno">
    <label class="um">
      Nome do aluno
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="usuario" />
      <input v-model="state.nome" type="text" spellCheck="false" placeholder="Ex: João Silva" @keydown.enter="criarAluno" />
    </div>

    <label>
      E-mail de acesso
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="envelope" />
      <input v-model="state.email" type="email" spellCheck="false" placeholder="email@email.com" @keydown.enter="criarAluno" />
    </div>

    <label>
      Whatsapp do aluno
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="whatsapp" />
      <input type="tel" spellCheck="false" placeholder="(00) 00000-0000" v-mask="mascaraAtual" v-model="numeroTelefone" @keydown.enter="criarAluno" />
    </div>

    <label>Senha do aluno será a padrão dê sua área, enviaremos para ele o acesso no e-mail</label>
    <div class="input">
      <Svgs class="icone" nome="cadeado" />
      <input type="text" spellcheck="false" :value="storeAreas.area?.senhaPadraoAlunos" readonly />
    </div>

    <SalvarModal nome="Criar Novo Aluno" @click="criarAluno()" />
  </Modal>
</template>

<script setup>
import { ref, watch, reactive, inject, onMounted } from 'vue'
import { useStoreAreas, useStoreAlunos, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeAlunos = useStoreAlunos()
const storeModal = useStoreModal()
const numeroTelefone = ref('')
const mascaraAtual = ref('(##) #####-####')

const state = reactive({
  nome: '',
  whatsapp: '',
  email: ''
})

async function criarAluno() {
  if (!validarDados()) return

  const idArea = storeAreas.area?._id

  const payload = {
    nome: state.nome,
    email: state.email,
    contato: {
      whatsapp: numeroTelefone.value
    }
  }

  const adicionadoOk = await storeAlunos.adicionar(idArea, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarAlunos')
  storeModal.fecharModal('criarAluno')
  limparCampos()
}

function limparCampos() {
  state.nome = ''
  state.email = ''
  state.senha = ''
  state.whatsapp = ''
  numeroTelefone.value = ''
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.email || !numeroTelefone.value) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

watch(numeroTelefone, (novoValor) => {
  const digitos = novoValor.replace(/\D/g, '')
  if (digitos.length <= 10) {
    mascaraAtual.value = '(##) ####-####'
  } else {
    mascaraAtual.value = '(##) #####-####'
  }
})

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
}
</style>
