<template>
  <ModalPersonalizacao nome="cursoEditarLayout">
    <template #um>
      <div class="botoes">
        <button @click="selecionarLayout('um')" :class="{ ativo: layoutAtivo === 'um' }">
          <img src="https://arquivos.levelmember.com/imagens/default/telas/layout-curso-1.svg" />
        </button>
        <button @click="selecionarLayout('dois')" :class="{ ativo: layoutAtivo === 'dois' }">
          <img src="https://arquivos.levelmember.com/imagens/default/telas/layout-curso-2.svg" />
        </button>
        <button @click="selecionarLayout('tres')" :class="{ ativo: layoutAtivo === 'tres' }">
          <img src="https://arquivos.levelmember.com/imagens/default/telas/layout-curso-3.svg" />
        </button>
      </div>
      <SalvarModal nome="Salvar Layout" @click="salvarDados()" />
    </template>
  </ModalPersonalizacao>
</template>

<script setup>
import { inject, computed, onMounted } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ModalPersonalizacao from '@components/global/modal/ModalPersonalizacao.vue'

const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()
const emitter = inject('emitter')

const storeAtualizada = computed(() => storePersonalizacao.personalizacao?.telaCurso)

const layoutAtivo = computed(() => storeAtualizada.value.layout || 'um')

function selecionarLayout(layout) {
  storeAtualizada.value.layout = layout
}

onMounted(() => {
  if (!storeAtualizada.value.layout) {
    storeAtualizada.value.layout = 'um'
  }
})

async function salvarDados() {
  const payload = {
    idArea: storeAreas.area._id,
    personalizacao: {
      telaCurso: storeAtualizada.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarArea')
}
</script>

<style scoped>
.botoes {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.botoes button {
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  padding: 0;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.botoes button.ativo {
  background-color: var(--cor-verde);
  padding: 10px;
  pointer-events: none;
}

.botoes button:hover {
  opacity: 0.7;
}

.botoes button img {
  width: 100%;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
}
</style>
