<template>
  <button
    :class="{ ativo: modalAberto === 'moduloEditarLayout' }"
    @click="
      abrirModal(
        'moduloEditarLayout', // nome do modal
        'degrade-azul', // cor do icone
        'modulo', // nome do icone
        'Tela do Módulo', // titulo
        'Editar Layout' // subtitulo
      )
    ">
    <p>Editar Layout</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'moduloEditarCores' }"
    @click="
      abrirModal(
        'moduloEditarCores', // nome do modal
        'degrade-azul', // cor do icone
        'modulo', // nome do icone
        'Tela do Módulo', // titulo
        'Editar Cores' // subtitulo
      )
    ">
    <p>Editar Cores</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'moduloEditarTextos' }"
    @click="
      abrirModal(
        'moduloEditarTextos', // nome do modal
        'degrade-azul', // cor do icone
        'modulo', // nome do icone
        'Tela do Módulo', // titulo
        'Editar Textos' // subtitulo
      )
    ">
    <p>Editar Textos</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'moduloEditarFontes' }"
    @click="
      abrirModal(
        'moduloEditarFontes', // nome do modal
        'degrade-azul', // cor do icone
        'modulo', // nome do icone
        'Tela do Módulo', // titulo
        'Editar Fontes' // subtitulo
      )
    ">
    <p>Editar Fontes</p>
  </button>
  <button
    :class="{ ativo: modalAberto === 'moduloEditarImagens' }"
    @click="
      abrirModal(
        'moduloEditarImagens', // nome do modal
        'degrade-azul', // cor do icone
        'modulo', // nome do icone
        'Tela do Módulo', // titulo
        'Editar Imagens' // subtitulo
      )
    ">
    <p>Editar Imagens</p>
  </button>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useStoreModal } from '@stores'

const storeModal = useStoreModal()
const modalAberto = ref('')
const emitter = inject('emitter')

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  if (modalAberto.value && modalAberto.value !== nome) {
    storeModal.fecharModal(modalAberto.value)
  }
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
  modalAberto.value = nome
}

function fecharModal() {
  modalAberto.value = ''
}

onMounted(() => {
  emitter.on('limparCampos', fecharModal)
})
</script>
