import helperToken from '@helpers/token'
import { api } from './axios-1'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  importarAlunos(idArea, payload) {
    const method = 'POST'
    const url = `importacoes/alunos/${idProdutor}/${idArea}`
    const dados = converterFormData(payload.arquivo, payload.idsCursos, payload.dataVencimento)

    return api(method, url, dados, token)
  }
}

function converterFormData(arquivo, idsCursos, dataVencimento) {
  const formData = new window.FormData()

  formData.append('arquivo', arquivo)
  formData.append('dataVencimento', dataVencimento)
  for (const id of idsCursos) {
    formData.append('idsCursos[]', id)
  }

  return formData
}
