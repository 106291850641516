import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiIntegracoes from '../api/produtor/api-integracoes'

export const useStoreIntegracoes = defineStore('storeIntegracoes', {
  state: () => {
    return {
      integracoes: null,
      integracao: null,
      configuracaoWebhook: null
    }
  },
  actions: {
    async receberTodos(idArea) {
      try {
        const resp = await apiIntegracoes.receberTodos(idArea)
        this.integracoes = resp
        return resp
      } catch (error) {
        this.integracoes = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorId(idArea, idIntegracao) {
      try {
        const resp = await apiIntegracoes.receberPorId(idArea, idIntegracao)
        this.integracao = resp
        return true
      } catch (error) {
        this.integracao = null
        return false
      }
    },

    async adicionar(idArea, payload) {
      try {
        const resp = await apiIntegracoes.adicionar(idArea, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(idArea, idIntegracao, payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiIntegracoes.alterarDados(idArea, idIntegracao, payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idArea, idIntegracao) {
      try {
        const resp = await apiIntegracoes.deletar(idArea, idIntegracao)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
