<template>
  <ModalPersonalizacao nome="moduloEditarFontes">
    <template #um>
      <div class="linha">
        <SalvarModal nome="Salvar" @click="salvarDados()" />
        <SelectFont :fonteInicial="storeAtualizada.fonte" @fonteSelecionada="atualizarFonte" />
      </div>
    </template>
  </ModalPersonalizacao>
</template>

<script setup>
import { inject, computed } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ModalPersonalizacao from '@components/global/modal/ModalPersonalizacao.vue'
import SelectFont from '@components/global/elementos/SelectFont.vue'

const storePersonalizacao = useStorePersonalizacao()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const atualizarFonte = (dados) => {
  storeAtualizada.value.fonte = dados.fonte
  carregarFonte(dados.fonte)
}

async function salvarDados() {
  const payload = {
    idArea: storeAreas.area._id,
    personalizacao: {
      telaModulo: storeAtualizada.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarArea')
}

function carregarFonte(fonte) {
  const fontFamily = fonte || 'Inter'
  document.documentElement.style.setProperty('--fonte-tela', fontFamily)

  const url = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}&display=swap`

  const linkExistente = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!linkExistente) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

const storeAtualizada = computed(() => storePersonalizacao.personalizacao?.telaModulo)
</script>

<style scoped>
.linha {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 20px 0 0 0;
}

button.salvarModal {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  max-width: 100px;
  height: 50px;
}

@media screen and (max-width: 1000px) {
  .linha {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 80px 0 0 0;
  }
  button.salvarModal {
    right: auto;
    left: 0;
    top: 20px;
    max-width: 100%;
  }
}
</style>
