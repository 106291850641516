<template>
  <ModalPersonalizacao nome="inicioEditarTextos">
    <template #um>
      <div class="campos">
        <label>Texto Aulas</label>
        <textarea rows="1" spellcheck="false" placeholder="Texto aqui..." v-model="storeAtualizada.textoAulas" v-redimensionar-textarea></textarea>

        <label>Texto Botão</label>
        <textarea rows="1" placeholder="Texto aqui..." spellcheck="false" autocomplete="false" v-model="storeAtualizada.textoBotao" v-redimensionar-textarea></textarea>

        <label>Título do Banner</label>
        <textarea rows="1" spellcheck="false" placeholder="Texto aqui..." v-model="storeAtualizada.textoCapaTitulo" v-redimensionar-textarea></textarea>

        <label>Descrição do Banner</label>
        <textarea rows="1" spellcheck="false" placeholder="Texto aqui..." v-model="storeAtualizada.textoCapaDescricao" v-redimensionar-textarea></textarea>

        <label>Texto Botão do Banner</label>
        <textarea rows="1" spellcheck="false" placeholder="Texto aqui..." v-model="storeAtualizada.textoCapaBotao" v-redimensionar-textarea></textarea>
      </div>
      <SalvarModal nome="Salvar Textos" @click="salvarDados()" />
    </template>
  </ModalPersonalizacao>
</template>

<script setup>
import { inject, computed } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ModalPersonalizacao from '@components/global/modal/ModalPersonalizacao.vue'

const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()
const emitter = inject('emitter')

async function salvarDados() {
  const payload = {
    idArea: storeAreas.area._id,
    personalizacao: {
      telaCursosArea: storeAtualizada.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarArea')
}

const storeAtualizada = computed(() => storePersonalizacao.personalizacao?.telaInicio)
</script>

<style scoped>
.campos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
}
</style>
