<template>
  <div id="senha">
    <Tela />
  </div>
  <!-- Modals -->
  <ModalEditarCores />
  <ModalEditarFontes />
  <ModalEditarTextos />
  <ModalEditarImagens />
  <ModalEditarLinks />
  <ModalEditarLayout />
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import { useRouter } from 'vue-router'

import Tela from '@components/areaDeMembros/personalizacao/telas/senha/partials/Tela.vue'
import ModalEditarCores from '@components/areaDeMembros/personalizacao/telas/senha/modals/EditarCores.vue'
import ModalEditarFontes from '@components/areaDeMembros/personalizacao/telas/senha/modals/EditarFontes.vue'
import ModalEditarTextos from '@components/areaDeMembros/personalizacao/telas/senha/modals/EditarTextos.vue'
import ModalEditarImagens from '@components/areaDeMembros/personalizacao/telas/senha/modals/EditarImagens.vue'
import ModalEditarLinks from '@components/areaDeMembros/personalizacao/telas/senha/modals/EditarLinks.vue'
import ModalEditarLayout from '@components/areaDeMembros/personalizacao/telas/senha/modals/EditarLayout.vue'

const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()
const router = useRouter()
const emitter = inject('emitter')

async function carregarDados() {
  const hotlinkArea = router.currentRoute.value.params.hotlinkArea
  await storeAreas.receberPorHotlink(hotlinkArea)

  storePersonalizacao.carregarAlterarSenha(storeAreas.area?.personalizacao?.telaAlterarSenha)
  emitter.emit('dadosAlterarSenhaCarregados')
}

onMounted(() => {
  carregarDados()
})
</script>

<style scoped></style>
