<template>
  <section class="notificacoes">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar curso..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
        </select>
      </div>
    </div>
    <div v-for="notificacao in notificacoesFiltradas" :key="notificacao._id" class="curso">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${notificacao.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="nome">
        <h3>{{ notificacao.texto }}</h3>
        <p>{{ notificacao.titulo }}</p>
      </div>

      <div class="dados">
        <div class="dado" :class="{ visto: !notificacao.visto }">
          <Svgs :nome="notificacao.visto ? 'check' : 'sino'" />
          <p>{{ notificacao.visto ? 'Visto' : 'Não visto' }}</p>
        </div>
        <div class="dado">
          <Svgs nome="relogio" />
          <p>{{ formatarData(notificacao.dataRegistro) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="chat" />
          <p>{{ notificacao.tipo }}</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="entrar" @click="abrirNotificacao(notificacao._id)">
          <p>Ver notificação</p>
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStoreModal, useStoreNotificacoes } from '@stores'
import Svgs from '@svgs'

const storeNotificacoes = useStoreNotificacoes()
const storeModal = useStoreModal()

const searchQuery = ref('')
const selectedFilter = ref('todos')

const notificacoesFiltradas = computed(() => {
  let notificacoes = storeNotificacoes.notificacoes || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    notificacoes = notificacoes.filter((notificacao) => notificacao.texto.toLowerCase().includes(query) || notificacao.titulo.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      notificacoes = notificacoes.slice().sort((a, b) => a.texto.localeCompare(b.texto))
      break
    case 'alfabetica-desc':
      notificacoes = notificacoes.slice().sort((a, b) => b.texto.localeCompare(a.texto))
      break
    case 'mais-antigos':
      notificacoes = notificacoes.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      notificacoes = notificacoes.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
  }

  return notificacoes
})

function formatarData(data) {
  const agora = new Date()
  const dataObjeto = new Date(data)

  const diferencaEmMilissegundos = agora - dataObjeto
  const diferencaEmMinutos = Math.floor(diferencaEmMilissegundos / (1000 * 60))
  const diferencaEmHoras = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60))
  const diferencaEmDias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

  if (diferencaEmMinutos < 60) {
    return `há ${diferencaEmMinutos} minuto${diferencaEmMinutos !== 1 ? 's' : ''}`
  } else if (diferencaEmHoras < 24) {
    return `há ${diferencaEmHoras} hora${diferencaEmHoras !== 1 ? 's' : ''}`
  } else if (diferencaEmDias <= 7) {
    return `há ${diferencaEmDias} dia${diferencaEmDias !== 1 ? 's' : ''}`
  } else {
    return dataObjeto.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    })
  }
}

async function abrirNotificacao(idNotificacao) {
  storeModal.abrirModalEdicao('verNotificacao', 'sino', 'Ver Notificação', idNotificacao)

  await storeNotificacoes.receberPorId(idNotificacao)

  if (storeNotificacoes.notificacao?.visto === false) {
    const payload = {
      visto: true
    }
    storeNotificacoes.alterarVisto(idNotificacao, payload)
  }
}
</script>

<style scoped>
section.notificacoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  min-height: 100dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.busca .input {
  width: calc(80% - 8px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 8px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.curso {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-1);
  border-top: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.curso:last-child {
  border-bottom: 1px solid var(--cor-cinza-3);
}

.curso .capa {
  width: 100%;
  max-width: 110px;
}

.curso .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.curso .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.curso .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.curso .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.curso .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.curso .opcoes {
  display: flex;
  align-items: center;
}

.curso .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s;
}

.curso .opcoes button.duplicar {
  margin: 0 10px;
}

.curso .opcoes button.editar {
  margin: 0 0 0 10px;
}

.curso .opcoes button.excluir {
  margin: 0;
}

.curso .opcoes button.entrar {
  justify-content: space-between;
  width: 140px;
  padding: 0 15px;
}

.curso .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.curso .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.curso .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.curso .dados {
  display: flex;
  align-items: center;
}

.curso .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.curso .dados .dado:nth-child(1) {
  border-left: none;
}

.curso .dados .dado.visto svg {
  fill: var(--cor-vermelho);
}

.curso .dados .dado svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
}

.curso .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.curso .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.cursos {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(65% - 8px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 8px);
  }

  .curso {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .curso .capa {
    width: 100%;
    max-width: 120px;
  }

  .curso .nome {
    margin: 0;
    width: calc(100% - 120px);
  }

  .curso .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .curso .opcoes button {
    margin: 0;
  }

  .curso .opcoes button.duplicar {
    margin: 0 10px 0 0;
  }

  .curso .opcoes button.editar {
    margin: 0 10px;
  }

  .curso .dados {
    padding: 20px 0;
    width: 100%;
  }

  .curso .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .curso .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .curso .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
