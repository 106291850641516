<template>
  <section class="suporte">
    <div class="quadro" @click="router.push(`/areas/${storeAreas.area?.hotlink}/dominio`)">
      <div class="icone">
        <Svgs nome="dominio" />
      </div>
      <div class="nome">
        <h3>Domínio</h3>
        <p>Configure seu domínio personalizado</p>
      </div>
    </div>

    <div class="quadro breve">
      <div class="icone">
        <Svgs nome="check" />
      </div>
      <div class="nome">
        <h3>Em breve</h3>
        <p>Novas atualizações em construção</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreAreas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const router = useRouter()
</script>

<style scoped>
section.suporte {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: calc(50% - 10px);
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  transition: all 0.3s;
}

.quadro.breve {
  pointer-events: none;
  background-color: var(--cor-cinza-1);
  border: 1px solid var(--cor-cinza-3);
}

.quadro:hover {
  border: 1px solid var(--cor-laranja);
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin: 0 15px 0 0;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.quadro .icone svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.quadro .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  max-width: 280px;
}

@media screen and (max-width: 1000px) {
  section.suporte {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .quadro {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .quadro .icone svg {
    width: 20px;
    min-width: 20px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
    margin: 5px 0 5px 0;
  }

  .quadro .nome p {
    font-size: var(--f1);
    max-width: 100%;
    margin: 0 0 0 0;
  }
}
</style>
