import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberPorId () {
    const method = 'GET'
    const url = `perfil/${idProdutor}`

    return api(method, url, null, token)
  },

  alterarDados (payload) {
    const method = 'PUT'
    const url = `perfil/${idProdutor}`
    const dados = payload

    return api(method, url, dados, token)
  },

  alterarSenha (payload) {
    const method = 'PATCH'
    const url = `perfil/senha/alterar/${idProdutor}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
