import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiPainel from '../api/produtor/api-painel'

export const useStorePainel = defineStore('storePainel', {
  state: () => {
    return {
      estatisticas: null
    }
  },
  actions: {
    async receberEstatisticas() {
      try {
        const resp = await apiPainel.receberEstatisticas()
        this.estatisticas = resp
        return resp
      } catch (error) {
        this.estatisticas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
