<template>
  <section class="modulo" :class="tela.layout" :style="conteudoEstilo">
    <div class="menu">
      <h3>Menu</h3>
      <p>
        Personalize na aba
        <Svgs nome="menu" />
        Menu da Área
      </p>
    </div>
    <!-- Apresentação -->
    <div class="apresentacao">
      <div class="sombra" :style="estiloSombra"></div>
      <div class="fundo desktop" :style="capaEstilo"></div>
      <div class="fundo mobile" :style="capaMobileEstilo"></div>
      <div class="texto">
        <div class="nome">
          <h2 :style="tituloEstilo">Nome do módulo</h2>
          <p :style="textoItemEstilo">Descrição do módulo</p>
        </div>
        <div class="progresso" :style="infoEstilo">
          <div class="faixa" :style="progressoFaixaEstilo">
            <div class="atual" :style="progressoAtualEstilo"></div>
          </div>
          <h3 :style="textoItemEstilo">
            4 de 31 aulas concluídas
            <span>20%</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="conteudo">
      <div class="elemento" v-for="aula in state.aulas" :key="aula">
        <div class="aula">
          <div class="capa">
            <div class="tamanho">
              <div class="imagem" style="background-image: url('https://arquivos.levelmember.com/imagens/default/background-vertical.svg')"></div>
            </div>
          </div>
          <div class="info" :style="infoEstilo">
            <h5 :style="posicaoEstilo">{{ aula.posicao }}º</h5>
            <div class="nome">
              <h3 :style="tituloEstilo">{{ aula.nome }}</h3>
              <p :style="tituloEstilo">{{ aula.descricao }}</p>
            </div>
            <div class="item">
              <Svgs :style="svgItemEstilo" nome="relogio" />
              <p :style="textoItemEstilo">{{ aula.duracao }} minutos</p>
            </div>
            <div class="botoes">
              <button class="editar" :style="botaoEstilo">
                {{ tela.textoBotao || 'Assistir' }}
              </button>
            </div>
          </div>
        </div>
        <div class="caminho um" :style="caminhoEstilo"></div>
        <div class="caminho dois" :style="caminhoEstilo"></div>
        <div class="caminho tres" :style="caminhoEstilo"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { useStorePersonalizacao } from '@stores'
import Svgs from '@svgs'

const storePersonalizacao = useStorePersonalizacao()

const state = reactive({
  aulas: [
    {
      posicao: '1',
      nome: 'Aula 1',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    },
    {
      posicao: '2',
      nome: 'Aula 2',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    },
    {
      posicao: '3',
      nome: 'Aula 3',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    },
    {
      posicao: '4',
      nome: 'Aula 4',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    },
    {
      posicao: '5',
      nome: 'Aula 5',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    },
    {
      posicao: '6',
      nome: 'Aula 6',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    },
    {
      posicao: '7',
      nome: 'Aula 7',
      descricao: 'Essa é uma aula!',
      duracao: '3'
    }
  ]
})

const tela = computed(() => storePersonalizacao.personalizacao?.telaModulo || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const capaEstilo = computed(() => ({
  backgroundImage: `url('${tela.value.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`
}))

const capaMobileEstilo = computed(() => ({
  backgroundImage: `url('${tela.value.imagemCapaMobile || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`
}))

const estiloSombra = computed(() => ({
  backgroundImage: `linear-gradient(to top, ${tela.value.corFundo || '#E1E3EB'}, transparent)`
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao || '#262836',
  color: tela.value.corTextoBotao || '#FFFFFF'
}))

const infoEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro || '#FFFFFF'
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulos || '#262836'
}))

const svgItemEstilo = computed(() => ({
  fill: tela.value.corTags || '#262836'
}))

const textoItemEstilo = computed(() => ({
  color: tela.value.corTags || '#262836'
}))

const posicaoEstilo = computed(() => ({
  color: tela.value.corPosicaoTexto || '#E1E3EB',
  backgroundColor: tela.value.corPosicaoFundo || '#262836'
}))

const caminhoEstilo = computed(() => ({
  borderColor: tela.value.corCaminho || '#64677c'
}))

function atualizarFonte() {
  const fontFamily = tela.value.fonte || 'Inter'
  document.documentElement.style.setProperty('--fonte-tela', fontFamily)

  const url = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}&display=swap`

  const linkExistente = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!linkExistente) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watch(
  () => storePersonalizacao.personalizacao?.telaModulo,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.modulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 300px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-gelo);
  width: 300px;
  height: calc(100dvh - 90px);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.menu h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  margin: 0 0 10px 0;
}

.menu p {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

.menu p svg {
  width: 11px;
  min-width: 11px;
  fill: var(--cor-cinza);
  margin: 0 5px;
}

.apresentacao {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100px;
  width: 100%;
}

.apresentacao .sombra {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.apresentacao .fundo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
}

.apresentacao .fundo.desktop {
  display: flex;
}

.apresentacao .fundo.mobile {
  display: none;
}

.apresentacao .texto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 0 30px;
  z-index: 3;
}

.apresentacao .texto .nome h2 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #ffffff;
  line-height: 1.2;
}

.apresentacao .texto .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.apresentacao .texto .progresso {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  padding: 20px;
}

.apresentacao .texto .progresso h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  width: 100%;
  margin: 10px 0 0 0;
}

.apresentacao .texto .progresso h3 span {
  font-size: var(--f1);
}

.apresentacao .texto .progresso .faixa {
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #ffffffa0;
}

.apresentacao .texto .progresso .faixa .atual {
  width: 40%;
  height: 5px;
  border-radius: 20px;
  background-color: #fff;
}

.conteudo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
}

section.modulo.tres .conteudo {
  padding: 30px 20px 30px 20px;
}

section.modulo.dois .conteudo {
  flex-direction: column;
}

.elemento {
  display: flex;
  justify-content: center;
  position: relative;
}

section.modulo.tres .elemento:nth-child(3n + 1) {
  justify-content: center;
  padding: 0 0 30px 0;
}

section.modulo.tres .elemento:nth-child(3n + 2) {
  padding: 0 0 30px 0;
}

section.modulo.tres .elemento:nth-child(3n) {
  justify-content: center;
  padding: 0 0 30px 0;
}

section.modulo.dois .elemento:nth-child(3n + 1) {
  width: 100%;
  padding: 0;
}

section.modulo.dois .elemento:nth-child(3n + 2) {
  width: 100%;
  padding: 0;
}

section.modulo.dois .elemento:nth-child(3n) {
  width: 100%;
  padding: 0;
}

.elemento:nth-child(3n + 1) {
  justify-content: flex-start;
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-child(3n + 2) {
  justify-content: center;
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-child(3n) {
  justify-content: flex-end;
  width: 33.33%;
  padding: 0 0 100px 0;
}

section.modulo.tres .caminho {
  display: none;
}

section.modulo.dois .caminho {
  display: none;
}

.caminho {
  position: absolute;
  z-index: 1;
}

.elemento:nth-last-child(1) .caminho {
  display: none;
}

.elemento:nth-child(6n + 1) .caminho.dois {
  display: none;
}

.elemento:nth-child(6n) .caminho.dois {
  display: none;
}

/* Caminho elementos A */

.elemento:nth-child(3n + 1) .caminho.dois {
  width: 2px;
  height: 100px;
  left: 125px;
  bottom: 0px;
  background-color: transparent;
  border-left: 3px dashed;
}

.elemento:nth-child(3n + 1) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.um {
  width: calc(50dvw - 530px);
  left: 250px;
  top: 200px;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed;
}

/* Caminho elementos B */

.elemento:nth-child(3n + 2) .caminho.um {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.dois {
  display: none;
}

/* Caminho elementos C */

.elemento:nth-child(3n) .caminho.um {
  display: none;
}

.elemento:nth-child(3n) .caminho.tres {
  width: calc(50dvw - 530px);
  right: 250px;
  top: 200px;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed;
}

.elemento:nth-child(3n) .caminho.dois {
  width: 2px;
  height: 100px;
  right: 125px;
  bottom: 0px;
  background-color: transparent;
  border-left: 3px dashed;
}

section.modulo.tres .aula {
  max-width: 100%;
  padding: 0 10px;
}

section.modulo.dois .aula {
  align-items: stretch;
  flex-direction: row;
  max-width: 100%;
  margin: 0 0 20px 0;
}

.aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 300px;
}

section.modulo.tres .aula .capa {
  max-width: 100%;
}

section.modulo.dois .aula .capa {
  max-width: 150px;
}

section.modulo.dois .aula .capa .tamanho .imagem {
  border-radius: 10px 0 0 10px;
}

.aula .capa {
  width: 100%;
  max-width: 300px;
  z-index: 2;
}

.aula .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.aula .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

section.modulo.dois .aula .info {
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px 0 0;
  border-radius: 0 10px 10px 0;
}

.aula .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  width: 100%;
  position: relative;
  z-index: 3;
}

section.modulo.dois .aula .info h5 {
  top: 10px;
  right: auto;
  left: -20px;
}

.aula .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dee0e6;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  text-align: center;
}

section.modulo.dois .aula .info .nome {
  padding: 0 0 0 40px;
  width: auto;
}

.aula .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.aula .info .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
  line-height: 1.5;
}

.aula .info .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
}

section.modulo.dois .aula .info .item {
  padding: 0 30px 0 0;
  margin-left: auto;
  width: auto;
}

.aula .info .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 20px 10px 20px;
}

.aula .info .item p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #64677c;
}

.aula .info .item svg {
  width: 15px;
  min-width: 15px;
  fill: #64677c;
  margin: 0 5px 0 0;
}

section.modulo.dois .aula .info .botoes {
  padding: 0;
  width: auto;
}

.aula .info .botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px 20px 20px;
}

.aula .info .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #64677c;
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 50px;
  transition: all 0.3s;
}

.aula .info .botoes button:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  section.modulo {
    padding: 0;
  }

  .menu {
    display: none;
  }

  .apresentacao {
    flex-direction: column;
    justify-content: flex-end;
    height: 250px;
  }

  .apresentacao .fundo {
    opacity: 0.3;
  }

  .apresentacao .fundo.desktop {
    display: none;
  }

  .apresentacao .fundo.mobile {
    display: flex;
  }

  .apresentacao .texto {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 20px 20px 20px 20px;
    z-index: 3;
  }

  .apresentacao .texto .progresso {
    width: 100%;
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  .conteudo {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  section.modulo.tres .conteudo {
    padding: 0 10px 20px 10px;
  }

  .elemento {
    flex-direction: column;
  }

  section.modulo.tres .elemento:nth-child(3n + 1) {
    justify-content: center;
    padding: 0 0 30px 0;
  }

  section.modulo.tres .elemento:nth-child(3n + 2) {
    padding: 0 0 30px 0;
  }

  section.modulo.tres .elemento:nth-child(3n) {
    justify-content: center;
    padding: 0 0 30px 0;
  }

  section.modulo.dois .elemento:nth-child(3n + 1) {
    width: 100%;
    padding: 0;
  }

  section.modulo.dois .elemento:nth-child(3n + 2) {
    width: 100%;
    padding: 0;
  }

  section.modulo.dois .elemento:nth-child(3n) {
    width: 100%;
    padding: 0;
  }

  .elemento:nth-child(3n + 1) {
    justify-content: center;
    width: 100%;
    padding: 0 0 100px 0;
  }

  .elemento:nth-child(3n + 2) {
    justify-content: center;
    width: 100%;
    padding: 0 0 100px 0;
  }

  .elemento:nth-child(3n) {
    justify-content: center;
    width: 100%;
    padding: 0 0 100px 0;
  }

  section.modulo.tres .caminho {
    display: none !important;
  }

  section.modulo.dois .caminho {
    display: none !important;
  }

  .elemento:nth-last-child(1) .caminho {
    display: none !important;
  }

  .elemento:nth-child(6n + 1) .caminho.dois {
    display: flex;
  }

  .elemento:nth-child(6n) .caminho.dois {
    display: flex;
  }

  /* Caminho elementos A */

  .elemento:nth-child(3n + 1) .caminho.dois {
    display: flex;
    left: 50%;
    bottom: 0;
  }

  .elemento:nth-child(3n + 1) .caminho.um {
    display: none;
  }

  /* Caminho elementos B */

  .elemento:nth-child(3n + 2) .caminho.um {
    display: none;
  }

  .elemento:nth-child(3n + 2) .caminho.tres {
    display: none;
  }

  .elemento:nth-child(3n + 2) .caminho.dois {
    display: flex;
    width: 2px;
    height: 100px;
    left: 50%;
    bottom: 0px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  /* Caminho elementos C */

  .elemento:nth-child(3n) .caminho.um {
    display: none;
  }

  .elemento:nth-child(3n) .caminho.tres {
    display: none;
  }

  .elemento:nth-child(3n) .caminho.dois {
    width: 2px;
    height: 100px;
    right: 50%;
    bottom: 0px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  section.modulo.tres .aula {
    max-width: 100%;
    padding: 0 10px;
  }

  section.modulo.dois .aula {
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  .aula {
    max-width: 100%;
  }

  section.modulo.dois .aula .capa {
    max-width: 100%;
  }

  section.modulo.dois .aula .capa .tamanho .imagem {
    border-radius: 10px 10px 0 0;
  }

  .aula .capa {
    max-width: 100%;
  }

  section.modulo.dois .aula .info {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px 20px;
    border-radius: 0 0 10px 10px;
  }

  .aula .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    width: 100%;
    position: relative;
    z-index: 3;
  }

  section.modulo.dois .aula .info h5 {
    top: -15px;
    right: auto;
    left: 20px;
  }

  .aula .info h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #dee0e6;
    font-family: var(--fonte-tela);
    font-size: var(--f2);
    color: #262836;
    text-align: center;
  }

  section.modulo.dois .aula .info .nome {
    align-items: center;
    padding: 0;
    width: 100%;
  }

  .aula .info .nome h3 {
    font-size: var(--f2);
  }

  .aula .info .nome p {
    font-size: var(--f1);
  }

  section.modulo.dois .aula .info .item {
    padding: 10px 0 0 0;
    margin-left: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .aula .info .item p {
    font-size: var(--f1);
  }

  .aula .info .item svg {
    width: 13px;
    min-width: 13px;
  }

  section.modulo.dois .aula .info .botoes {
    padding: 10px 0 0 0;
  }
}
</style>
