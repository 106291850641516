import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()

export default {
  entrar(payload) {
    const method = 'POST'
    const url = 'autenticacao/entrar'
    const dados = payload

    return api(method, url, dados, token)
  },

  registrar(payload) {
    const method = 'POST'
    const url = 'autenticacao/registrar'
    const dados = payload

    return api(method, url, dados, token)
  },
}
