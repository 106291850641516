<template>
  <ModalPersonalizacao nome="moduloEditarImagens">
    <template #um>
      <div v-for="(imagem, index) in imagens" :key="index" class="inputFile">
        <button @click="carregarImagem(index)" :disabled="imagem.desabilitarBotao">
          <span></span>
          <Svgs nome="arquivo" />
          <h3>{{ imagem.titulo }}</h3>
          <p>{{ imagem.descricao }}</p>
          <UploadImagem :dados="imagem" ref="uploadImagens" @atualizarDadosImagem="(dados) => atualizarDadosImagem(dados, index)" />
        </button>
        <div class="previa" :style="`background-image: url('${imagem.urlImagem || ''}')`">
          <button class="excluir" v-if="imagem.urlImagem" @click="excluirImagem(imagem.chave)">
            <Svgs nome="excluir" />
          </button>
        </div>
      </div>
      <SalvarModal nome="Salvar Imagens" @click="salvarDados()" />
    </template>
  </ModalPersonalizacao>
</template>

<script setup>
import { inject, computed, reactive, ref, onMounted, watch } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ModalPersonalizacao from '@components/global/modal/ModalPersonalizacao.vue'
import UploadImagem from '@components/global/upload/image.vue'
import Svgs from '@svgs'

const storePersonalizacao = useStorePersonalizacao()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')
const uploadImagens = ref([])

const imagens = reactive([
  {
    titulo: 'Capa Desktop',
    descricao: '1500x300',
    urlImagem: '',
    desabilitarBotao: false,
    chave: 'imagemCapa'
  },
  {
    titulo: 'Capa Mobile',
    descricao: '600x300',
    urlImagem: '',
    desabilitarBotao: false,
    chave: 'imagemCapaMobile'
  }
])

async function salvarDados() {
  const payload = {
    idArea: storeAreas.area._id,
    personalizacao: {
      telaModulo: storeAtualizada.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarArea')
}

function excluirImagem(nome) {
  storeAtualizada.value[nome] = ''
}

function atualizarDadosImagem(dados, index) {
  if (typeof dados.desabilitarBotao === 'boolean') imagens[index].desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    imagens[index].urlImagem = dados.urlImagem
    storeAtualizada.value[imagens[index].chave] = dados.urlImagem
  }
}

function carregarImagem(index) {
  const uploadImagem = uploadImagens.value[index]
  uploadImagem?.enviarImagem()
}

const storeAtualizada = computed(() => storePersonalizacao.personalizacao?.telaModulo)

function carregarImagensIniciais() {
  imagens.forEach((imagem) => {
    imagem.urlImagem = storeAtualizada.value[imagem.chave] || ''
  })
}

onMounted(() => {
  carregarImagensIniciais()
})

watch(
  storeAtualizada,
  (novoValor) => {
    carregarImagensIniciais()
  },
  { deep: true }
)
</script>

<style scoped>
.inputFile {
  margin: 20px 0 0 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
}
</style>
