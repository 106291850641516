<template>
  <div id="notificacoes">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalVerNotificacao />
    <SectionLista />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreNotificacoes, useStoreAreas } from '@stores'

import Loading from '@components/global/loading/LoadingArea.vue'
import ModalVerNotificacao from '@components/areaDeMembros/notificacoes/modals/VerNotificacao.vue'
import SectionLista from '@components/areaDeMembros/notificacoes/partials/SectionLista.vue'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeNotificacoes = useStoreNotificacoes()

const state = reactive({
  verLoading: true
})

async function carregarDados() {
  state.verLoading = true
  const idArea = storeAreas.area?._id

  if (idArea) {
    await storeNotificacoes.receberTodos(idArea)
  }

  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

watch(
  () => [storeAreas.area?._id],
  ([idArea]) => {
    if (idArea) {
      carregarDados()
    }
  },
  { immediate: true }
)

onMounted(() => {
  carregarDados()
  emitter.on('atualizarNotificacoes', carregarDados)
})
</script>

<style scoped>
#notificacoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
