import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberPorId(idArea, idIntegracao) {
    const method = 'GET'
    const url = `integracoes/${idProdutor}/${idArea}/${idIntegracao}`

    return api(method, url, null, token)
  },

  receberTodos(idArea) {
    const method = 'GET'
    const url = `integracoes/${idProdutor}/${idArea}`

    return api(method, url, null, token)
  },

  adicionar(idArea, payload) {
    const method = 'POST'
    const url = `integracoes/${idProdutor}/${idArea}`
    const dados = payload

    return api(method, url, dados, token)
  },

  alterarDados(idArea, idIntegracao, payload) {
    const method = 'PUT'
    const url = `integracoes/${idProdutor}/${idArea}/${idIntegracao}`
    const dados = payload

    return api(method, url, dados, token)
  },

  deletar(idArea, idIntegracao) {
    const method = 'DELETE'
    const url = `integracoes/${idProdutor}/${idArea}/${idIntegracao}`
    const dados = {}

    return api(method, url, dados, token)
  }
}
