<template>
  <section class="modulos">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar módulo..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
        </select>
      </div>
    </div>
    <div v-for="modulo in modulosFiltrados" :key="modulo._id" class="modulo">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${modulo.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="nome">
        <h3>{{ modulo.nome }}</h3>
        <p>{{ modulo.descricao }}</p>
      </div>

      <div class="dados">
        <div class="dado">
          <Svgs nome="play" />
          <p>
            <span>{{ modulo.quantidadeAulas }}</span>
          </p>
          <p>{{ textoAulas(modulo.quantidadeAulas) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="relogio" />
          <p>
            <span>{{ modulo.totalDuracao }}</span>
          </p>
          <p>minutos</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="excluir" @click="abrirModalEdicao('excluirModulo', 'excluir', 'Excluir módulo', modulo._id)">
          <Svgs nome="excluir" />
        </button>
        <button class="editar" @click="abrirModalEdicao('editarModulo', 'editar', 'Editar módulo', modulo._id)">
          <Svgs nome="editar" />
        </button>
        <button class="duplicar" @click="abrirModalEdicao('duplicarModulo', 'duplicar', 'Duplicar módulo', modulo._id)">
          <Svgs nome="duplicar" />
        </button>
        <button class="entrar" @click="irParaAulas(modulo._id)">
          <p>Ver aulas</p>
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStoreModal, useStoreAreas, useStoreModulos, useStoreCursos } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeModal = useStoreModal()
const router = useRouter()

const searchQuery = ref('')
const selectedFilter = ref('todos')

const modulosFiltrados = computed(() => {
  let modulos = storeModulos.modulos || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    modulos = modulos.filter((modulo) => modulo.nome.toLowerCase().includes(query) || modulo.descricao.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      modulos = modulos.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      modulos = modulos.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      modulos = modulos.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      modulos = modulos.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
  }

  return modulos
})

function irParaAulas(idModulo) {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  storeModulos.receberPorId(idArea, idCurso, idModulo)
  setTimeout(() => {
    router.push(`/areas/${storeAreas.area?.hotlink}/aulas`)
  }, 300)
}

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}
</script>

<style scoped>
section.modulos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 100dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.busca .input {
  width: calc(80% - 10px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 10px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.modulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-1);
  border-top: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.curso:last-child {
  border-bottom: 1px solid var(--cor-cinza-3);
}

.modulo .capa {
  width: 100%;
  max-width: 110px;
}

.modulo .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.modulo .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.modulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.modulo .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.modulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.modulo .opcoes {
  display: flex;
  align-items: center;
}

.modulo .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s;
}

.modulo .opcoes button.duplicar {
  margin: 0 10px;
}

.modulo .opcoes button.editar {
  margin: 0 0 0 10px;
}

.modulo .opcoes button.excluir {
  margin: 0;
}

.modulo .opcoes button.entrar {
  justify-content: space-between;
  width: 140px;
  padding: 0 15px;
}

.modulo .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.modulo .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.modulo .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.modulo .dados {
  display: flex;
  align-items: center;
}

.modulo .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.modulo .dados .dado:nth-child(1) {
  border-left: none;
}

.modulo .dados .dado svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
}

.modulo .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.modulo .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.modulos {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(65% - 8px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 8px);
  }

  .modulo {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .modulo .capa {
    width: 100%;
    max-width: 120px;
  }

  .modulo .nome {
    margin: 0;
    width: calc(100% - 120px);
  }

  .modulo .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .modulo .opcoes button {
    margin: 0;
  }

  .modulo .opcoes button.duplicar {
    margin: 0 10px 0 0;
  }

  .modulo .opcoes button.editar {
    margin: 0 10px;
  }

  .modulo .dados {
    padding: 20px 0;
    width: 100%;
  }

  .modulo .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .modulo .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .modulo .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
