<template>
  <div id="personalizacao">
    <Loading :class="{ mostrar: state.verLoading }" />
    <SectionTelas />
    <Tela />
  </div>
</template>

<script setup>
import { onMounted, inject, reactive, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAreas } from '@stores'
import Loading from '@components/global/loading/LoadingPersonalizacao.vue'
import SectionTelas from '@components/areaDeMembros/personalizacao/partials/SectionTelas.vue'
import Tela from '@components/areaDeMembros/personalizacao/partials/Tela.vue'

const storeAreas = useStoreAreas()
const router = useRouter()

const emitter = inject('emitter')

const state = reactive({
  verLoading: false
})

function mostrarLoading() {
  state.verLoading = true
  setTimeout(() => {
    state.verLoading = false
  }, 1000)
}

async function carregarArea() {
  const hotlink = router.currentRoute.value.params.hotlinkArea
  if (storeAreas.area?.hotlink !== hotlink) {
    await storeAreas.receberPorHotlink(hotlink)
    document.title = storeAreas.area?.nome + ' | Level Member'
  }
}

const hotlinkArea = computed(() => router.currentRoute.value.params.hotlinkArea)

watch(hotlinkArea, (newHotlink) => {
  if (storeAreas.area?.hotlink !== newHotlink) {
    carregarArea()
  }
}, { immediate: true })

onMounted(() => {
  emitter.on('atualizarArea', carregarArea)
  emitter.on('mostrarLoading', mostrarLoading)
})

</script>

<style scoped>
#personalizacao {
  padding: 90px 0 0 0;
}
</style>
