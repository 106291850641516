<template>
  <ModalPersonalizacao nome="inicioEditarCores">
    <template #um>
      <div class="botoes">
        <button @click="abrirSeletorCor('corFundo')">
          <h3>Cor do fundo</h3>
          <p>{{ storeAtualizada.corFundo }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corFundo}`"></div>
        </button>

        <button @click="abrirSeletorCor('corQuadro')">
          <h3>Cor do quadro</h3>
          <p>{{ storeAtualizada.corQuadro }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corQuadro}`"></div>
        </button>

        <button @click="abrirSeletorCor('corTextos')">
          <h3>Cor dos textos</h3>
          <p>{{ storeAtualizada.corTextos }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corTextos}`"></div>
        </button>

        <button @click="abrirSeletorCor('corTextosDois')">
          <h3>Cor dos subtextos</h3>
          <p>{{ storeAtualizada.corTextosDois }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corTextosDois}`"></div>
        </button>

        <button @click="abrirSeletorCor('corTextoCapa')">
          <h3>Textos do Banner</h3>
          <p>{{ storeAtualizada.corTextoCapa }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corTextoCapa}`"></div>
        </button>

        <button @click="abrirSeletorCor('corTextoBotao')">
          <h3>Texto do Botão</h3>
          <p>{{ storeAtualizada.corTextoBotao }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corTextoBotao}`"></div>
        </button>

        <button @click="abrirSeletorCor('corFundoBotao')">
          <h3>Fundo do Botão</h3>
          <p>{{ storeAtualizada.corFundoBotao }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corFundoBotao}`"></div>
        </button>

        <button @click="abrirSeletorCor('corProgressoFundo')">
          <h3>Cor fundo progresso</h3>
          <p>{{ storeAtualizada.corProgressoFundo }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corProgressoFundo}`"></div>
        </button>

        <button @click="abrirSeletorCor('corProgressoAtual')">
          <h3>Cor progresso atual</h3>
          <p>{{ storeAtualizada.corProgressoAtual }}</p>
          <div class="cor" :style="`background-color: ${storeAtualizada.corProgressoAtual}`"></div>
        </button>
      </div>

      <SalvarModal nome="Salvar Cores" @click="salvarDados()" />
    </template>

    <template #dois>
      <div class="seletorCor fixo" v-if="selecionandoCor">
        <div class="nome">
          <h3>Selecionar cor</h3>
          <button class="ocultar" @click="ocultarSeletor()"><Svgs nome="x" /></button>
        </div>
        <ColorPicker class="elemento" :key="corPickerKey" :color="corSelecionada" @update:color="mudarCor" />
      </div>
    </template>
  </ModalPersonalizacao>
</template>

<script setup>
import { inject, ref, computed, watch } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ModalPersonalizacao from '@components/global/modal/ModalPersonalizacao.vue'
import ColorPicker from '@components/global/elementos/ColorPicker.vue'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()
const emitter = inject('emitter')

const corSelecionada = ref('#FFFFFF')
const selecionandoCor = ref(false)
const propriedadeSelecionada = ref('')
const corPickerKey = ref(0)

function mudarCor(novaCor) {
  const hexColor = novaCor.hex || novaCor
  corSelecionada.value = hexColor
  if (propriedadeSelecionada.value) {
    storeAtualizada.value[propriedadeSelecionada.value] = hexColor
  }
}

function abrirSeletorCor(propriedade) {
  propriedadeSelecionada.value = propriedade
  corSelecionada.value = storeAtualizada.value[propriedade] || '#FFFFFF'
  selecionandoCor.value = true
  corPickerKey.value += 1
}

function ocultarSeletor() {
  selecionandoCor.value = false
}

async function salvarDados() {
  const payload = {
    idArea: storeAreas.area._id,
    personalizacao: {
      telaCursosArea: storeAtualizada.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarArea')
}

const storeAtualizada = computed(() => storePersonalizacao.personalizacao?.telaInicio)

watch(
  () => storeAtualizada.value,
  (novoValor) => {
    if (novoValor && propriedadeSelecionada.value) {
      corSelecionada.value = novoValor[propriedadeSelecionada.value] || '#FFFFFF'
    }
  },
  { deep: true }
)
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

.botoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 0 0;
}

.botoes button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 60px 15px 15px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-branco);
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

.botoes button:hover {
  border: 2px solid var(--cor-cinza-2);
}

.botoes button .cor {
  position: absolute;
  height: 100%;
  width: 35px;
  border-radius: 0 10px 10px 0;
  right: 0;
  top: 0;
}

.botoes button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza);
}

.botoes button h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .botoes button {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 60px 20px 20px;
    margin: 0 0 10px 0;
  }

  .botoes button p {
    margin: 10px 0 0 0;
  }

  .botoes button .cor {
    position: absolute;
    height: 100%;
    width: 45px;
    border-radius: 0 10px 10px 0;
    right: 0;
    top: 0;
  }
}
</style>
