import { onMounted, onBeforeMount } from 'vue'

function clicouFora(alvosEFuncoes) {
  if (!alvosEFuncoes || !alvosEFuncoes.length) return

  let ouvinte = (e) => {
    for (let { alvo, retornoChamada } of alvosEFuncoes) {
      if (alvo && alvo.value && alvo.value.contains) {
        if (e.target !== alvo.value && !alvo.value.contains(e.target)) {
          if (typeof retornoChamada === 'function' && retornoChamada) retornoChamada()
        }
      }
    }
  }

  onMounted(() => {
    window.addEventListener('click', ouvinte)
  })
  onBeforeMount(() => {
    window.removeEventListener('click', ouvinte)
  })

  return { ouvinte }
}

export default { clicouFora }
