import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiAulas from '../api/produtor/api-aulas'

export const useStoreAulas = defineStore('storeAulas', {
  state: () => {
    return {
      aulas: null,
      aula: null
    }
  },
  actions: {
    async receberTodos(idArea, idCurso, idModulo) {
      try {
        const resp = await apiAulas.receberTodos(idArea, idCurso, idModulo)
        this.aulas = resp
        return resp
      } catch (error) {
        this.aulas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(idArea, idCurso, idModulo, hotlink) {
      try {
        const resp = await apiAulas.receberPorHotlink(idArea, idCurso, idModulo, hotlink)
        this.aula = resp
        return true
      } catch (error) {
        this.aula = null
        return false
      }
    },

    async receberPorId(idArea, idCurso, idModulo, idAula) {
      try {
        const resp = await apiAulas.receberPorId(idArea, idCurso, idModulo, idAula)
        this.aula = resp
        return true
      } catch (error) {
        this.aula = null
        return false
      }
    },

    async adicionar(idArea, idCurso, idModulo, payload) {
      try {
        const resp = await apiAulas.adicionar(idArea, idCurso, idModulo, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async duplicar(idArea, idCurso, idModulo, idAula) {
      try {
        const resp = await apiAulas.duplicar(idArea, idCurso, idModulo, idAula)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(idArea, idCurso, idModulo, idAula, payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiAulas.alterarDados(idArea, idCurso, idModulo, idAula, payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idArea, idCurso, idModulo, idAula) {
      try {
        const resp = await apiAulas.deletar(idArea, idCurso, idModulo, idAula)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async ordenarPosicoes(idArea, idCurso, idModulo, payload) {
      try {
        const resp = await apiAulas.ordenarPosicoes(idArea, idCurso, idModulo, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
